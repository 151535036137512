import React, { useState } from 'react';
import { 
  Box, Typography, Modal, IconButton, Tooltip, TextField, 
  Button, Stack, Divider, Chip 
} from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import { useTheme } from '@mui/material/styles';

const EmbedModal = ({ product }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const baseUrl = window.buildENV === 'staging' ? 'https://stage.goldwaterbullion.com' : 'https://www.goldwaterbullion.com';

  const embedUrl = `${baseUrl}/embed/${product?.slug || 'unknown'}`;
  const embedCode = `
<iframe
  title="embedded-product"
  src="${embedUrl}"
  width="450"
  height="180"
  frameBorder="0"
  allowfullscreen
></iframe>`;

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(embedCode);
    } catch (error) {
      console.error('Failed to copy embed code:', error);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Tooltip title="Embed this product">
        <IconButton onClick={handleOpen}>
          <CodeIcon />
        </IconButton>
      </Tooltip>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Embed Product
          </Typography>

          <Divider sx={{ my: 2 }} />

          <Stack direction="row" spacing={2} alignItems="flex-start">
            {/* Embed Code Section */}
            <Box sx={{ flex: 1 }}>
              <Typography variant="subtitle1" gutterBottom>
                Embed Code
              </Typography>
              <TextField
                multiline
                fullWidth
                value={embedCode}
                variant="outlined"
                InputProps={{ readOnly: true }}
              />
              <Button 
                variant="contained" 
                onClick={handleCopy} 
                sx={{ mt: 1 }}
              >
                Copy Embed Code
              </Button>
            </Box>

            <Divider orientation="vertical" flexItem />

            {/* Preview Section */}
            <Box 
              sx={{ 
                flex: 1, 
                backgroundColor: '#f5f5f5', // Light gray background for preview
                borderRadius: 2,
                padding: 2,
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 2 }}>
                <Chip label="Preview" color="primary" />
              </Box>

              {/* Load Preview in iFrame */}
              <iframe
                title="embedded-product"
                src={embedUrl}
                width="100%"
                height="180"
                frameBorder="0"
                allowFullScreen
                style={{ borderRadius: '8px' }}
              ></iframe>
            </Box>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default EmbedModal;
