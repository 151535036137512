import React from 'react';
import { Grid, Typography, IconButton, Button, Container, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Alert from '@mui/material/Alert';

const WithdrawAccountPreview = ({ selectedTransferMethod, userWithdrawMethods, amount, handleBack, handleConfirm, isButtonDisabled, error }) => {
    const selectedMethod = userWithdrawMethods.find(method => method.id === selectedTransferMethod);

    return (
        <Container>
            <Box>
                {/* Display error alert if there is an error */}
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}
                
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        <IconButton onClick={handleBack} color="primary" aria-label="go back">
                            <ArrowBackIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h6" color="text.primary" gutterBottom sx={{ textAlign: 'left' }}>
                            Confirm
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                            To:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom align="right">
                            {selectedMethod ? `${selectedMethod.id} (****${selectedMethod.id.slice(-4)})` : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                            From:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom align="right">
                            USD Wallet
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                            Funds Will Arrive:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom align="right">
                            2-3 Days
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                            Goldwater Bullion Exchange Fee:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom align="right">
                            $0.00
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                            Total:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom align="right">
                            ${parseFloat(amount).toFixed(2)}
                        </Typography>
                    </Grid>
                </Grid>
                <Button
                    onClick={handleConfirm}
                    variant="contained"
                    color="primary"
                    disabled={isButtonDisabled}
                    sx={{ mt: 2 }}
                    fullWidth
                >
                    Withdraw Funds
                </Button>
            </Box>
        </Container>
    );
};

export default WithdrawAccountPreview;
