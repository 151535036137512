import React, { useState, useEffect } from 'react';
import { Container, CircularProgress, Box, Typography } from '@mui/material';
import WithdrawMethodsSelector from './WithdrawMethodsSelector';
import WithdrawConfirmationScreen from './WithdrawConfirmationScreen';
import WithdrawAccountPreview from './WithdrawAccountPreview';
import Alert from '@mui/material/Alert';
import { Button } from '@mui/material';

const WithdrawModal = ({ isButtonDisabled, setIsButtonDisabled, onClose }) => {
    const [userWithdrawMethods, setWithdrawAccounts] = useState([]);
    const [selectedTransferMethod, setSelectedTransferMethod] = useState('');
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [previewMode, setPreviewMode] = useState(false);
    const [confirmationScreen, setConfirmationScreen] = useState(false);
    const isLocal = process.env.REACT_APP_LOCAL === 'true';

    const fetchWithdrawAccounts = async () => {
        setLoading(true);
        try {
            if (isLocal) {
                const fixtureData = await import('./fixtures/withdraw_methods.json');
                setWithdrawAccounts([fixtureData.id]); // Use the top-level ID
            } else {
                const response = await fetch('/api/banking/status');
                const data = await response.json();
                setWithdrawAccounts([data.id]); // Use the top-level ID from the API response
            }
        } catch (error) {
            console.error("Error fetching withdraw accounts:", error);
            setWithdrawAccounts([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchWithdrawAccounts();
    }, []);

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setPreviewMode(true);
    };
    const handleConfirm = async () => {
        const amountInCents = amount * 100;
        console.log("Confirming withdrawal with amount in cents:", amountInCents);
        setIsButtonDisabled(true);
    
        try {
            let result;
    
            if (isLocal) {
                // Load mock data from the file
                const mockData = await import('./fixtures/transfer_mock.json');
                result = {
                    ...mockData,
                    amount: amountInCents,  // Adjust the amount to match the current input
                    destination: selectedTransferMethod  // Adjust the destination to match the selected method
                };
                console.log("Mock transfer result from file:", result);
            } else {
                // Real API call
                const response = await fetch('/api/banking/transfer', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        connected_account_id: selectedTransferMethod,
                        amount: amountInCents,
                        description: "User withdraw request"
                    })
                });
    
                // Check the response status code
                if (!response.ok) {
                    const errorData = await response.json();
                    const errorMessage = errorData.error || 'An unexpected error occurred';
                    if (response.status === 400) {
                        throw new Error(`Invalid request: ${errorMessage}`);;
                    } else if (response.status === 403) {
                        throw new Error('You are not authorized to perform this action.');
                    } else if (response.status === 500) {
                        throw new Error('A server error occurred. Please try again later.');
                    } else {
                        throw new Error(`An unexpected error occurred (Status code: ${response.status})`);
                    }
                }
    
                result = await response.json();
                console.log("Real transfer result:", result);
            }
    
            if (result.success) {
                setSuccess('Account funded successfully!');
                setConfirmationScreen(true);
                setPreviewMode(false);
                setAmount('');
                setSelectedTransferMethod('');
            } else {
                setError('Failed to fund account: ' + result.error);
            }
        } catch (error) {
            console.error("An error occurred during transfer:", error);
            setError(error.message);  // Set the specific error message to the error state
        } finally {
            setIsButtonDisabled(false);
        }
    };
        
    const handleBack = () => {
        setPreviewMode(false);
    };

    const handleCloseAlert = () => {
        setSuccess(null);
        setError(null);
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (confirmationScreen) {
        return <WithdrawConfirmationScreen onClose={onClose} />;
    }

    if (userWithdrawMethods.length === 0) {
        return "no Withdraw Accounts setup"
    }

    if (previewMode) {
        return (
            <WithdrawAccountPreview
                selectedTransferMethod={selectedTransferMethod}
                userWithdrawMethods={userWithdrawMethods}
                amount={amount}
                handleBack={handleBack}
                handleConfirm={handleConfirm}
                isButtonDisabled={isButtonDisabled}
                error={error}  // Pass error to WithdrawAccountPreview
            />
        );
    }

    return (
        <Container>
            <Box>
                <Typography variant="h6" color="text.primary" gutterBottom>
                    Withdraw
                </Typography>

                {/* Render Alert based on success or error */}
                {success && <Alert severity="success" onClose={handleCloseAlert}>{success}</Alert>}
                {error && <Alert severity="error" onClose={handleCloseAlert}>{error}</Alert>}

                <form onSubmit={handleFormSubmit}>
                    <WithdrawMethodsSelector
                        userWithdrawMethods={userWithdrawMethods}
                        selectedTransferMethod={selectedTransferMethod}
                        setSelectedTransferMethod={setSelectedTransferMethod}
                        amount={amount}
                        setAmount={setAmount}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isButtonDisabled}
                    >
                        Preview
                    </Button>
                </form>
            </Box>
        </Container>
    );
};

export default WithdrawModal;
