import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, Typography, Paper, Avatar, Fab, Button, Divider, Badge, IconButton 
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import goldBarImage from './clippy.png';
import md5 from 'md5';
import { useAuth } from '../../contexts/AuthContext';
import FundingForm from '../IndexPage/FundAccount';
import ChatView from './ChatView';

const GoldBar = () => {
  const { auth } = useAuth();
  const [showHelper, setShowHelper] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chatLog, setChatLog] = useState([]);
  const [customQuestion, setCustomQuestion] = useState('');
  const [isChatView, setIsChatView] = useState(false);

  const emailHash = auth.user ? md5(auth.user.username.trim().toLowerCase()) : null;
  const verified = auth.user ? auth.user.verified : 'not_started';
  const tos = auth.user ? auth.user.tos_accepted : false;
  const payment_method_ids = auth.user ? auth.user.payment_method_ids : [];
  const payouts_enabled = auth.user ? auth.user.payouts_enabled : false;
  const gravatarUrl = emailHash ? `https://www.gravatar.com/avatar/${emailHash}?d=identicon` : null;

  const baseUrl = window.buildENV === 'staging' ? 'https://stage.goldwaterbullion.com' : 'https://www.goldwaterbullion.com';

  const determineBadgeContent = (verified, payouts_enabled, isLoggedIn, payment_method_ids, tos) => {
    let content = 0;
    if (!isLoggedIn) {
      return 0
    }
    if (!tos) content += 1
    if (payment_method_ids && payment_method_ids.length <= 0) content += 1
    if (verified !== 'verified') content += 1;
    if (!payouts_enabled) content += 1;
    return content;
  };
  
  const badgeContent = determineBadgeContent(verified, payouts_enabled, auth.user !== null, payment_method_ids, tos);

  const getWelcomeMessage = (verified, payoutsEnabled, isLoggedIn, payment_method_ids) => {
    if (!isLoggedIn) {
      return "Howdy, stranger! I'm Gold Bar, You'll need to log in to start tradin' coins. Saddle up and join the marketplace!";
    } else if (verified !== 'verified') {
      return "Howdy, partner! It looks like you need to get verified to start coin tradin'. Pew pew!";
    } else if (!payoutsEnabled) {
      return "Howdy, partner! You need to enable payouts to start selling coins. Let's get you set up!";
    } else if (payment_method_ids &&  payment_method_ids.length > 0) {
      return "Howdy, partner! To purchase gold and silver you'll need to set up a payment account. I'll guide you!";
    } else if (!tos) {
      return "Howdy, partner! To purchase gold and silver you'll need to read over our terms of service. I'll guide you!";
    } else {
      return "Howdy, partner! I'm Gold Bar, here to help you navigate the world of coin tradin'. Pew pew!";
    }
  };

  const questionButtons = (
    verified,
    payoutsEnabled,
    isLoggedIn,
    tos,
    payment_method_ids,
    handleVerificationQuestion,
    handleCustomChatClick,
    handlePayoutsQuestion,
    handleWelcomeQuestion,
    handlePaymentAccountQuestion,
    handleWhyTwoAccountsQuestion,
    handleDifferenceQuestion,
    handleOrderbookQuestion,
    handleTosQuestion
  ) => {
    if (!isLoggedIn) {
      return (
        <>
        <Button
          variant="contained"
          fullWidth
          sx={{ mb: 1 }}
          onClick={handleWelcomeQuestion}
        >
          What is Goldwater Bullion Exchange?
        </Button>

        <Button
        variant="contained"
        fullWidth
        sx={{ mb: 1 }}
        onClick={handleDifferenceQuestion}
      >
        How are you different than JM Bullion or APMEX?
      </Button>
      <Button
        variant="contained"
        fullWidth
        sx={{ mb: 1 }}
        onClick={handleOrderbookQuestion}
      >
        What's an Orderbook?
      </Button>
      
      </>
      );
    }
  
    if (verified !== 'verified') {
      return (
        <Button
          variant="contained"
          fullWidth
          sx={{ mb: 1 }}
          onClick={handleVerificationQuestion}
        >
          How do I verify my identity and why?
        </Button>
      );
    }
  
    if (!payoutsEnabled) {
      return (
        <Button
          variant="contained"
          fullWidth
          sx={{ mb: 1 }}
          onClick={handlePayoutsQuestion}
        >
          How do I enable payouts?
        </Button>
      );
    }

    if (payment_method_ids && payment_method_ids.length <= 0) {
      return (
        <>
        <Button
          variant="contained"
          fullWidth
          sx={{ mb: 1 }}
          onClick={handlePaymentAccountQuestion}
        >
          How do I add a payment method?
        </Button>
        <Button
          variant="contained"
          fullWidth
          sx={{ mb: 1 }}
          onClick={handleWhyTwoAccountsQuestion}
        >
          Why do I need to set up both a payment method and payout account?
        </Button>
        </>
      );
    }

    if (!tos) {
    return (
      <Button
        variant="contained"
        fullWidth
        sx={{ mb: 1 }}
        onClick={handleTosQuestion}
      >
        What are the terms of service?
      </Button>
      )
    }
  
    return (
      <>

        <Button
          variant="contained"
          fullWidth
          sx={{ mb: 1 }}
          onClick={handleBidQuestion}
        >
           How do I place a bid?
        </Button>
        <Button
          variant="contained"
          fullWidth
          sx={{ mb: 1 }}
          onClick={handleDepositQuestion}
        >
          How do I add money to my wallet?
        </Button>

        <Button
          variant="contained"
          fullWidth
          sx={{ mb: 1 }}
          onClick={handleAccountIssue}
        >
          I have an account and billing issue.
        </Button>

        
      </>
    );
  };
  

  const handleHelperClick = () => {
    setShowHelper(!showHelper);
    if (!showHelper) resetState();
  };

  const resetState = () => {
    setIsChatView(false);
    setChatLog([]);
    setCustomQuestion('');
  };

  const handleVerificationQuestion = () => {
    const userMessage = { sender: 'user', text: 'How do I verify my identity and why?' };
    const rawGoldBarResponse = `Well now, folks, here’s the straight of it. If you’re gonna buy or sell gold with us here at Goldwater Bullion Exchange, we need to make sure you’re *you*. Simple as that.
  
See, when you’re dealin’ with somethin’ as valuable as gold, we’ve gotta keep things buttoned-up tight. Verifying your identity ain’t just some bureaucratic red tape—we do it so ever’body involved can rest easy. Helps us ensure the gold goes to the right hands and keeps any bad actors from sneakin’ into the marketplace.

If you’re sellin', we wanna know that what you’re sendin’ is legitimate, and if you’re buyin', well, we gotta make sure you’re the rightful owner of that account we’re shippin’ gold to. Keeps things square and proper—the Texas way.

At the end of the day, it’s all about trust. You trust us to get you the best price, and we make sure ever’body playin' by the same rules. And that’s somethin’ worth more than gold itself, if you ask me.`;

    const goldBarResponse = { 
      sender: 'goldBar', 
      text: rawGoldBarResponse,
      links: [
        { link: `${baseUrl}/partner/onboarding`, copy: 'Verify your identity here' },
        { link: 'https://stripe.com/identity', copy: 'Learn how your idenity is used by our partner' }
      ]};
    setChatLog([userMessage, goldBarResponse]);
    setIsChatView(true);
  };

  const handlePayoutsQuestion = () => {
    const userMessage = { sender: 'user', text: 'How do I set up a payout account?' };
  
    const rawGoldBarResponse = `Well now, let me lay it out plain for ya. When you're dealin' with somethin' as serious as gold, we gotta make sure all the pieces line up just right. If you’re sellin' here at Goldwater Bullion Exchange, we need a few things from you—one of 'em bein' a payout account.
  
    The first step is settin’ up through our partner, Stripe. They're the folks that handle our payments, makin’ sure that, once your gold is verified and good to go, your payment lands in your account through ACH. You can set this up lickety-split by followin’ the link below.
  
    Trust is the name of the game. We verify every bit of metal that comes our way—whether you’re sendin’ gold or buyin’ it. Once your gold is verified at our Texas facility, you'll get paid through ACH, usually within 1-3 days. And for those lucky few, Stripe might even offer instant payouts.
  
    So head on over to the link, get your payout account squared away, and let’s get ya on the path to tradin’ gold with the best of ‘em—the Texas way.`;
  
    const goldBarResponse = { 
      sender: 'goldBar', 
      text: rawGoldBarResponse,
      links: [
        { link: `${baseUrl}/onboarding/banking`, copy: 'Set up a Payout account here' }
      ]
    };
  
    setChatLog([userMessage, goldBarResponse]);
    setIsChatView(true);
  };

const [showFundAccountModal, setShowFundAccountModal] = useState(false);
const handlePaymentAccountQuestion = () => {
  const userMessage = { sender: 'user', text: 'How do I set up a payment account?' };

  const rawGoldBarResponse = `Alright, let me walk you through it. If you’re fixin’ to buy gold here at Goldwater Bullion Exchange, the first step is settin’ up an ACH account through our partner, Stripe. 
  
  This ACH account will be used to fund your wallet. Once it's set up, you can transfer funds into your wallet to place bids on gold or silver. Your money will stay safe and sound until your bid matches a seller's ask, and the order is verified by our team at the Texas facility.

  Stripe ensures your funds are secure every step of the way. Once your bid clears, your payment will be held until we give the gold the all-clear, and then it’s shipped to you with full insurance. Easy as pie.

  Follow the link below to get your ACH account set up and start fundin' your wallet. From there, you'll be ready to join the marketplace with confidence.`;

  const goldBarResponse = { 
    sender: 'goldBar', 
    text: rawGoldBarResponse,
    links: [],
    modalLinks: [{ copy: 'Open Payment Setup' }]
  };

  setChatLog([userMessage, goldBarResponse]);
  setIsChatView(true);
};


  const handleWhyTwoAccountsQuestion = () => {
    const userMessage = { sender: 'user', text: 'Why do I need to set up both a payment method and payout account?' };
  
    const rawGoldBarResponse = `That’s a fair question, partner. I can see how it might feel like you're travelin' the same road twice. But these are two different flows through Stripe, each with its own purpose.
  
    The **payout account** is for when you’re sellin’ gold—after we verify your metals at our Texas facility, the funds get deposited into this account via ACH.
  
    On the other hand, the **payment method** is needed for buyin'. It’s also an ACH account, but this one is used to fund your wallet ahead of time. When you place a bid, the funds are temporarily held to secure the transaction until the gold is verified and shipped your way.
  
    These separate accounts keep things smooth and secure, makin’ sure your transactions go off without a hitch. It’s all part of keepin’ things above board and runnin' like clockwork.`;
  
    const goldBarResponse = { 
      sender: 'goldBar', 
      text: rawGoldBarResponse,
      links: [
        { link: `${baseUrl}/onboarding/banking`, copy: 'Setup a payout account here (selling)' }
      ],
      modalLinks: [{ copy: 'Setup a payment method (buying)' }]
    };
  
    setChatLog([userMessage, goldBarResponse]);
    setIsChatView(true);
  };

  const handleBidQuestion = () => {
    const userMessage = { sender: 'user', text: 'How do I place a bid?' };
  
    const rawGoldBarResponse = "Placin' a bid is as easy as saddlin’ up for a ride, partner. Here’s how you can do it:\n\n" +
      "1. Find Your Market – Search through our product list to find the gold or silver you’re lookin' for.\n" +
      "2. Fund Your Account – Make sure you’ve got enough funds in your wallet. You’ll need to deposit through ACH to secure your bids.\n" +
      "3. Place Your Bid – Once you spot the right deal, enter the amount you’re willin’ to pay and the quantity you want. The funds will be temporarily held until the deal clears.\n" +
      "4. Match and Win – When your bid matches a seller’s ask, the order clears instantly, and the process is set in motion.\n\n" +
      "If you run into any questions along the way, don’t hesitate to reach out at support@goldwaterbullion.com. We’ll make sure you’re ready to trade like a pro, the Texas way.";
  
    const goldBarResponse = {
      sender: 'goldBar',
      text: rawGoldBarResponse,
      links: [{ link: baseUrl, copy: 'Find your Market' }],
      modalLinks: [{ copy: 'Fund your account' }]
    };
  
    setChatLog([userMessage, goldBarResponse]);
    setIsChatView(true);
  };
  

  const handleAccountIssue = () => {
    const userMessage = { sender: 'user', text: 'I have an account and billing issue.' };
  
    const rawGoldBarResponse = `I hear ya, partner. If you’re runnin' into trouble with your account or billing, we’ve got your back. Just shoot us an email at **support@goldwaterbullion.com**, and we’ll get right on it. 
  
    Our team’s ready to help ya sort things out quick and smooth—whether it’s a billing discrepancy or account access issue. We’re committed to makin’ sure everything’s squared away so you can stay focused on tradin' with confidence.
  
    Don’t hesitate—reach out now, and we’ll take care of the rest.`;
  
    const goldBarResponse = {
      sender: 'goldBar',
      text: rawGoldBarResponse,
      links: []
    };
  
    setChatLog([userMessage, goldBarResponse]);
    setIsChatView(true);
  };
  
  const handleDepositQuestion = () => {
    const userMessage = { sender: 'user', text: 'How do I add money to my wallet?' };
  
    const rawGoldBarResponse = `Here at Goldwater Bullion Exchange, your wallet works like a secure vault. Before you can place a bid on gold, you need to add money to it. Think of it like preparing your bankroll for a poker game—you gotta have chips on the table to play. This ensures that when you bid, the funds are held safely until the gold is verified and shipped. No surprises, no delays. The reason we do it this way? It keeps things smooth and secure. The seller knows the money’s already in place, and you can bid with confidence, knowing you're set to claim your gold without a hitch.`;
  
    const goldBarResponse = { 
      sender: 'goldBar', 
      text: rawGoldBarResponse,
      links: [],
      modalLinks: [{ copy: 'Add money to your wallet' }]
    };
  
    setChatLog([userMessage, goldBarResponse]);
    setIsChatView(true);
  };
  

  const handleWelcomeQuestion = () => {
    const userMessage = { sender: 'user', text: 'What is Goldwater Bullion Exchange?' };
  
    const rawGoldBarResponse = `Well now, Goldwater Bullion Exchange is a marketplace where folks come together to trade gold and silver, plain and simple. Whether you’re buyin’, sellin’, or just lookin’ to hold onto somethin’ solid, we make sure the process is fair, secure, and smooth as a creek in springtime.
  
    It’s built for folks who believe in the value of precious metals—whether to hedge against hard times, invest for the future, or just collect somethin’ with a bit of history to it. We do things with care and integrity, and that’s somethin’ we take mighty serious.
  
    So whether you're here to trade a few coins or build a portfolio, we’re ready to help you every step of the way. Goldwater’s here to make sure you trade with confidence and know your gold’s in good hands.`;
  
    const goldBarResponse = { 
      sender: 'goldBar', 
      text: rawGoldBarResponse,
      links: [
        { link: `${baseUrl}/welcome`, copy: 'Join the marketplace' },
        { link: `${baseUrl}/login`, copy: 'Sign in' },
        { link: `${baseUrl}/blog/sell-with-confidence`, copy: 'Product details' }
      ]
    };
  
    setChatLog([userMessage, goldBarResponse]);
    setIsChatView(true);
  };

  const handleDifferenceQuestion = () => {
    const userMessage = { sender: 'user', text: 'How are we different than JM Bullion or APMEX?' };
  
    const rawGoldBarResponse = `Well now, that's a good question. Goldwater Bullion Exchange ain't like the others, and I'll tell you why. You see, we believe in the good ol’ principles of free-market capitalism. Here, folks can set their own prices with bids and asks—just like a stock exchange. We match buyers and sellers directly, so you're always in control of the price you pay.
  
    Now, places like JM Bullion or APMEX? They’re more like stores. You pay whatever price they set, take it or leave it. We reckon that's fine for some, but we wanted a place where the market speaks, not just the storekeeper.
  
    And here’s another thing: every piece of gold and silver that passes through our hands is verified at our secure Texas facility. We don't just glance at it—we check it under the finest methods and tools, every single ounce. Can’t say the same for some other places where you might get what's called 'sight-unseen' inventory.
  
    We take your trust seriously, partner. That’s why we insure every shipment and pay through secure ACH after verification. You won't find hidden fees or gimmicks here—just fair, transparent trading with the confidence that your gold is in the best hands. And that, my friend, is the Texas way.`;
  
    const goldBarResponse = { 
      sender: 'goldBar', 
      text: rawGoldBarResponse,
      links: [
        { link: `${baseUrl}/welcome`, copy: 'Join the marketplace' },
        { link: `${baseUrl}/login`, copy: 'Sign in' },
        { link: `${baseUrl}/blog/sell-with-confidence`, copy: 'Learn about our process' }
      ]
    };
  
    setChatLog([userMessage, goldBarResponse]);
    setIsChatView(true);
  };
  
  const handleOrderbookQuestion = () => {
    const userMessage = { sender: 'user', text: "What's an Orderbook?" };
  
    const rawGoldBarResponse = `Well now, an orderbook, it's the beating heart of a marketplace like ours. Think of it like a big ol’ ledger, keepin' track of all the bids folks are makin’ to buy, and the asks folks are postin’ to sell. Every time someone says, 'I’ll pay this much for that gold,' or 'I’m lookin' to sell at this price,' it gets written down right here.
  
    When a buyer's bid matches a seller's ask—well, that’s when the magic happens. The trade goes through, fair and square, without a middleman tellin’ you what’s what. It’s all transparent—everyone can see where their offer stands, just like watchin’ cattle get auctioned at the county fair.
  
    Our orderbook lets you set your own prices, control your trades, and see the whole picture—no funny business, just good ol' free market workin' the way it should. And with every trade backed by secure payments and insured shipping, you know your gold's in safe hands, from start to finish. That's the orderbook, and that's how we do it, the Texas way.`;
  
    const goldBarResponse = { 
      sender: 'goldBar', 
      text: rawGoldBarResponse,
      links: [
        { link: `${baseUrl}/welcome`, copy: 'Join the marketplace' },
        { link: `${baseUrl}/login`, copy: 'Sign in' },
        { link: `${baseUrl}/blog/sell-with-confidence`, copy: 'Orderbook details' }
      ]
    };
  
    setChatLog([userMessage, goldBarResponse]);
    setIsChatView(true);
  };
  
  const handleTosQuestion = () => {
    const userMessage = { sender: 'user', text: "What are the Terms of Service?" };
  
    const rawGoldBarResponse = `Well now, reckon the Terms of Service is kinda like the rules of the road, especially one tradin' in gold. Just like that orderbook keepin' track of bids and asks, the Terms lay out what's fair, what’s allowed, and what ain’t. It's the ground rules, so everyone knows they’re on solid footing. It makes sure folks can buy and sell on good terms, with no surprises when it comes to who’s responsible for what.
You can take a closer look if you're feelin' curious—there's a link right there for you to see it spelled out, plain as day.`;
  
    const goldBarResponse = { 
      sender: 'goldBar', 
      text: rawGoldBarResponse,
      links: [
        { link: `${baseUrl}/onboarding/tos`, copy: 'Terms of Service' },
      ]
    };
  
    setChatLog([userMessage, goldBarResponse]);
    setIsChatView(true);
  };

  const handleCustomChatClick = async (message) => {
    const userMessage = { sender: 'user', text: message };
    setChatLog((prev) => [...prev, userMessage]);
    setIsChatView(true);
    setLoading(true);
    try {
      const response = await fetch('/api/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message }),
      });
      const data = await response.json();
      const goldBarMessage = { sender: 'goldBar', text: data.response };
      setChatLog((prev) => [...prev, goldBarMessage]);
    } catch (error) {
      console.error('Error fetching chat response:', error);
      const errorMessage = { sender: 'goldBar', text: 'Sorry, something went wrong.' };
      setChatLog((prev) => [...prev, errorMessage]);
    }
    setLoading(false);
  };

  const handleCustomQuestionSubmit = (e) => {
    e.preventDefault();
    if (customQuestion.trim()) {
      handleCustomChatClick(customQuestion);
      setCustomQuestion('');
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 16,
        right: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      {showHelper ? (
        <Paper sx={{ width: 350, padding: 3, boxShadow: 3, borderRadius: 4, mb: 1 }}>
          {isChatView ? (
            <>
              <IconButton onClick={() => setIsChatView(false)} sx={{ position: 'absolute', top: 8, left: 8 }}>
                <ArrowBackIcon />
              </IconButton>
              <ChatView
                chatLog={chatLog}
                loading={loading}
                customQuestion={customQuestion}
                setCustomQuestion={setCustomQuestion}
                handleCustomQuestionSubmit={handleCustomQuestionSubmit}
                gravatarUrl={gravatarUrl}
                auth={auth}
                showFundAccountModal={showFundAccountModal}
                setShowFundAccountModal={setShowFundAccountModal}
              />
            </>
          ) : (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Avatar src={goldBarImage} alt="Gold Bar" sx={{ width: 60, height: 60, mr: 2 }} />
                  <Typography variant="h6" sx={{ textAlign: 'left' }}>
                    {getWelcomeMessage(verified, payouts_enabled, auth.user !== null)}
                  </Typography>
              </Box>
              <Divider sx={{ mb: 2 }} />
              <Box sx={{ mt: 2 }}>
                {questionButtons(verified, payouts_enabled, auth.user !== null, tos, payment_method_ids, handleVerificationQuestion, handleCustomChatClick, handlePayoutsQuestion, handleWelcomeQuestion, handlePaymentAccountQuestion, handleWhyTwoAccountsQuestion, handleDifferenceQuestion, handleOrderbookQuestion, handleTosQuestion)}
              </Box>
            </>
          )}
          <Button variant="outlined" fullWidth sx={{ mt: 1 }} onClick={handleHelperClick}>
            Close
          </Button>
        </Paper>
      ) : (
        <Badge
          badgeContent={badgeContent}
          color="error"
          overlap="circular"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            zIndex: 99999999
          }}
        >
          <Fab
            onClick={handleHelperClick}
            sx={{
              width: 80,
              height: 80,
              backgroundImage: `url(${goldBarImage})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              zIndex: -1
            }}
          />
        </Badge>
      )}
    </Box>
  );
};

export default GoldBar;
