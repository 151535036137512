import { Box, Typography, Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const CustomNoRowsOverlay = ({ filter }) => {
  const navigate = useNavigate();
  const isAllFilter = filter === 'all'; // Check if the 'all' tab is active
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      {isAllFilter ? (
        <>
          <img 
            src="https://slideby.s3.us-west-1.amazonaws.com/box.png" // Replace with your image URL
            alt="No orders"
            style={{
              marginBottom: '10px',
              maxWidth: '150px', // Set max width
              maxHeight: '150px', // Set max height
              objectFit: 'contain', // Ensure aspect ratio is maintained
            }}
          />
          <Typography>It looks like no orders have sold yet.</Typography>
          <Typography>You have nothing to ship until your ask matches a bid.</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/')}
            sx={{ mt: 2 }}
          >
            Place a Sell
        </Button>
        </>
      ) : (
        <Typography>No matching orders.</Typography>
      )}
    </Box>
  );
};

export default CustomNoRowsOverlay;
