import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';

const WithdrawMethodsSelector = ({ userWithdrawMethods, selectedTransferMethod, setSelectedTransferMethod, amount, setAmount }) => {
    return (
        <>
            <FormControl fullWidth margin="normal">
                <InputLabel id="payment-method-label">Transfer to</InputLabel>
                <Select
                    labelId="payment-method-label"
                    value={selectedTransferMethod}
                    onChange={(e) => setSelectedTransferMethod(e.target.value)}
                    label="Transfer to"
                >
                    {userWithdrawMethods.map((methodId, index) => (
                        <MenuItem key={index} value={methodId}>
                            {`Stripe Connect Account: (****${methodId.slice(-4)})`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <TextField
                label="Amount *fees apply"
                fullWidth
                margin="normal"
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
            />
        </>
    );
};

export default WithdrawMethodsSelector;
