// File: ProductPage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ProductPage.css';
import BuySell from './BuySell';
import MyOrders from './MyOrders';
import OrderBook from './OrderBook';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import { Typography, Box, Skeleton, Chip, Stack, useMediaQuery } from '@mui/material';
import TollIcon from '@mui/icons-material/Toll';
import Rating from '@mui/material/Rating';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import GoldBar from '../GoldBar/goldbar';
import CopyLinkButton from './CopyLinkButton'
import EmbedModal from './EmbedModal';


const ProductPage = () => {
  const [product, setProduct] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [mainImage, setMainImage] = useState("");
  const [limitPrice, setLimitPrice] = useState(3000); // Placeholder limit price
  const [lowestSellPrice, setLowestSellPrice] = useState(null); // State for lowest sell price
  const [marketSize, setMarketSize] = useState(0); // State for market size
  const [highestBuyPrice, setHighestBuyPrice] = useState(0); // State for highest buy price
  const { productName } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchProduct = async () => {
      const isLocal = process.env.REACT_APP_LOCAL === 'true';
      
      if (isLocal) {
        try {
          const staticResponse = await import('./fixtures/market.json');
          const product = staticResponse.product;
          const breadcrumbs = staticResponse.breadcrumbs;
          setProduct(product);
          setBreadcrumbs(breadcrumbs);
          setMainImage(product.image_one && product.image_one.length > 0 ? product.image_one : '/static/img/placeholder-main.png');
          if (product.price) {
            setLowestSellPrice(product.price);
            setLimitPrice(product.price);
          }
        } catch (error) {
          console.error("Error loading static data:", error);
        }
      } else {
        const apiUrl = `/api/products/${productName}`;
        try {
          const response = await axios.get(apiUrl);
          const product = response.data.product;
          const breadcrumbs = response.data.breadcrumbs;
          setProduct(product);
          setBreadcrumbs(breadcrumbs);
          setMainImage(product.image_one.length > 0 ? product.image_one : '/static/img/placeholder-main.png');
          if (product.price) {
            setLowestSellPrice(product.price);
            setLimitPrice(product.price);
          }
        } catch (error) {
          console.error("Error fetching product info:", error);
        }
      }
    };

    fetchProduct();
  }, [productName]); // Depend on productName to re-fetch product when it changes

  const handleThumbnailHover = (image) => {
    setMainImage(image.full);
  };

  return (
    <Container maxWidth="xl">
      <Stack spacing={2}>
        <div></div>
        {!isMobile && (
          <div>
            <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
              <Grid xs={12} sm={6} md={3}>
                <Chip icon={<TollIcon />} label="GLD-USD" variant="outlined" />
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <Box display="flex" alignItems="center" justifyContent="flex-start" height="6vh">
                  <Typography variant="overline" display="block" gutterBottom>
                    Market Size: ${marketSize.toLocaleString()}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <Box display="flex" alignItems="center" justifyContent="flex-start" height="6vh">
                  <Typography variant="overline" display="block" gutterBottom>
                    Highest bid: ${highestBuyPrice.toLocaleString()}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} sm={6} md={3}>
                <Box display="flex" alignItems="center" justifyContent="flex-start" height="6vh">
                  <Typography variant="overline" display="block" gutterBottom>
                    Lowest ask: {lowestSellPrice ? `$${lowestSellPrice}` : "$0"}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </div>
        )}
        <div></div>
      </Stack>
   
      <Container maxWidth="lg">
        <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start">
          <Grid xs={12} md={8}>
            <div className="product-c">
              <Box 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'space-between',  // Ensures space between breadcrumbs and button
                  width: '100%', 
                  margin: '0em 0' 
                }}
              >
                <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ margin: '1em' }}>
                  {breadcrumbs.map((breadcrumb, index) => (
                    <Link
                      underline="hover"
                      variant="caption"
                      key={index}
                      color="inherit"
                      href={`/?b=${breadcrumb.id}`}
                    >
                      {breadcrumb.name}
                    </Link>
                  ))}
                </Breadcrumbs>
                <Stack direction="row" spacing={1} alignItems="center">
                {product && <EmbedModal product={product} />}
                <CopyLinkButton /> 
                </Stack>
              </Box>
              <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start">
                <Grid xs={12} sm={4} md={2}>
                  {!isMobile && (
                    <div className="thumbnail-container">
                      {[
                        { thumbnail: product?.image_one_thumb, full: product?.image_one },
                        { thumbnail: product?.image_two_thumb, full: product?.image_two },
                        { thumbnail: product?.image_three_thumb, full: product?.image_three }
                      ]
                        .filter(image => image.thumbnail && image.full)
                        .map((image, index) => (
                          <img
                            key={index}
                            src={image.thumbnail}
                            alt={`Thumbnail ${index + 1}`}
                            className="thumbnail"
                            onMouseOver={() => handleThumbnailHover(image)}
                          />
                        ))
                      }
                      {
                        !product?.image_one && !product?.image_two && !product?.image_three && (
                          <img
                            src='https://slideby.s3.us-west-1.amazonaws.com/placeholder-image.png'
                            alt='Placeholder Thumbnail'
                            className='thumbnail'
                          />
                        )
                      }
                    </div>
                  )}
                </Grid>
                <Grid xs={12} sm={8} md={6}>
                  <div className="main-image-container">
                    <img src={mainImage} alt="Main Image" className="main-image" />
                  </div>
                </Grid>
                <Grid xs={12} md={4}>
                  <Box className="product-details">
                    <Typography variant="h4" component="h1">
                      {product ? product.title : <Skeleton variant="text" width={210} />}
                    </Typography>
                    <Box className="rating">
                      <Rating name="disabled" value={5} disabled />
                    </Box>
                    <Box className="price">
                      {product ? (
                        <Typography variant="h6">
                          {lowestSellPrice ? `$${lowestSellPrice}` : "Bid to make offer"}
                        </Typography>
                      ) : (
                        <Skeleton variant="text" width={100} />
                      )}
                    </Box>
                    <Box className="product-info">
                      <Typography variant="overline" display="block" gutterBottom>
                        Product details
                      </Typography>
                      {product ? (
                        <>
                          <Typography variant="body1">{product.purity}</Typography>
                          <Typography variant="body1">{product.material}</Typography>
                          <Typography variant="body1">{product.weight} {product.units}</Typography>
                        </>
                      ) : (
                        <Skeleton variant="text" width={300} />
                      )}
                    </Box>
                    <Box className="about">
                      <Typography variant="overline" display="block" gutterBottom>
                        About this item
                      </Typography>
                      {product ? (
                        <Typography variant="body1">{product.manufacturer}</Typography>
                      ) : (
                        <Skeleton variant="text" width={300} />
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid xs={12} md={4}>
            <div>
              <BuySell
                product={product ? product : ''}
                market={{ sell: lowestSellPrice }}
                limitPrice={lowestSellPrice || 0}
                setLimitPrice={setLimitPrice}
                onToggleProductId={() => console.log("Toggle Product ID")}
                onShowAssetSelector={() => console.log("Show Asset Selector")}
                lowestSellPrice={lowestSellPrice}
              />
            </div>
          </Grid>
          <Grid xs={12} md={8}>
            <div>
              <MyOrders productName={productName} marketId={product ? product.product_id : null} />
            </div>
          </Grid>
          <Grid xs={12} md={4}>
            <div>
              {product && (
                <OrderBook
                  key={product.product_id} // Ensure OrderBook updates by adding a key
                  marketId={product.product_id}
                  setLowestSellPrice={setLowestSellPrice}
                  setMarketSize={setMarketSize}
                  setHighestBuyPrice={setHighestBuyPrice}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
      <GoldBar />
    </Container>
  );
};

export default ProductPage;
