import React from 'react';
import { Snackbar, Alert, AlertTitle } from '@mui/material';

const AlertNotification = ({ success, error, handleCloseAlert }) => {
    return (
        <>
            {success && (
                <Snackbar open={!!success} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%' }}>
                        <AlertTitle>Success</AlertTitle>
                        {success}
                    </Alert>
                </Snackbar>
            )}
            {error && (
                <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseAlert}>
                    <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
                        <AlertTitle>Error</AlertTitle>
                        {error}
                    </Alert>
                </Snackbar>
            )}
        </>
    );
};

export default AlertNotification;
