import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const EmptyStateContainer = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(5),
}));

const EmptyImage = styled('img')(() => ({
  maxWidth: '200px',
  margin: '20px auto',
}));

const EmptyOrders = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <EmptyStateContainer>
        <Typography variant="h5" gutterBottom>
          Your Orders
        </Typography>
        <EmptyImage
          src="https://slideby.s3.us-west-1.amazonaws.com/box.png"
          alt="Empty box"
        />
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Looks like trades haven't matched yet.
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Nothing is on its way until your offer matches an ask.
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ my: 1 }}>
          — continue —
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/')}
          sx={{ mt: 2 }}
        >
          Place a Bid
        </Button>
      </EmptyStateContainer>
    </Container>
  );
};

export default EmptyOrders;
