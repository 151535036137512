// File: OrderBook.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './OrderBook.css';

const OrderBook = ({ marketId, setLowestSellPrice, setMarketSize, setHighestBuyPrice }) => {
  const [buyOrders, setBuyOrders] = useState([]);
  const [sellOrders, setSellOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      const isLocal = process.env.REACT_APP_LOCAL === 'true';
      let staticOrders = [];
  
      if (isLocal) {
        try {
          const staticData = await import('./fixtures/orders.json');
          staticOrders = staticData.default;
        } catch (error) {
          console.error("Error loading static orders:", error);
        }
      } else {
        try {
          const response = await axios.get(`/api/markets/${marketId}/orders`);
          staticOrders = response.data;
        } catch (error) {
          console.error("Error fetching orders:", error);
        }
      }
  
      if (staticOrders && Array.isArray(staticOrders)) {
        const buys = staticOrders.filter(order => order.type === 'buy' && order.price != null);
        const sells = staticOrders.filter(order => order.type === 'sell' && order.price != null);
  
        setBuyOrders(buys);
        setSellOrders(sells);
  
        if (sells.length > 0) {
          const lowestSellPrice = Math.min(...sells.map(order => order.price));
          setLowestSellPrice(lowestSellPrice / 100);
        } else {
          setLowestSellPrice(0);
        }
  
        if (buys.length > 0) {
          const highestBuyPrice = Math.max(...buys.map(order => order.price));
          setHighestBuyPrice(highestBuyPrice / 100);
        } else {
          setHighestBuyPrice(0);
        }
  
        const marketSize = sells.reduce((total, order) => total + (order.price * order.currentvolume), 0);
        setMarketSize(marketSize / 100);
      } else {
        console.error("Error: staticOrders is not an array or is undefined");
        setLowestSellPrice(0);
        setMarketSize(0);
        setHighestBuyPrice(0);
      }
    };
  
    fetchOrders();
  }, [marketId, setLowestSellPrice, setMarketSize, setHighestBuyPrice]);  

  return (
    <div className="order-book">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 250 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">AMOUNT</TableCell>
              <TableCell align="left">BID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {buyOrders.map((order) => (
              <TableRow key={order.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left" style={{ color: 'green' }}>{order.currentvolume}</TableCell>
                <TableCell align="left" style={{ color: 'green' }}>{order.price / 100}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Table sx={{ minWidth: 250 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="left">AMOUNT</TableCell>
              <TableCell align="left">ASK</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sellOrders.map((order) => (
              <TableRow key={order.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left" style={{ color: 'red' }}>{order.currentvolume}</TableCell>
                <TableCell align="left" style={{ color: 'red' }}>{order.price / 100}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default OrderBook;
