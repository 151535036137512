import React from 'react';
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';
import Tooltip from '@mui/material/Tooltip';

const CopyLinkButton = () => {
  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
    } catch (err) {
      console.error('Failed to copy the link: ', err);
    }
  };

  return (
    <Tooltip title="Copy page link">
      <IconButton onClick={handleCopyLink}>
        <LinkIcon />
      </IconButton>
    </Tooltip>
  );
};

export default CopyLinkButton;