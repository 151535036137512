import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const steps = ['Find your Market', 'Set the Price', 'Ship', 'Payment'];

const SellProcess = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Card sx={{ width: '100%', padding: 2, boxSizing: 'border-box' }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom sx={{ mb: 4 }}>
          SELLING PROCESS
        </Typography>
        <Stepper activeStep={activeStep} sx={{ pb: 3 }}>
          {steps.map((label, index) => (
            <Step key={label} completed={false}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length ? (
          <Box sx={{ textAlign: 'center' }}>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - Your sale is complete.
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        ) : (
          <Box>
            <Typography sx={{ mt: 2, mb: 1, textAlign: 'left' }}>
              {activeStep === 0
                ? 'Browse our markets to find the gold or silver you\'re selling. Whether it\’s a 1-ounce Gold Eagle or Silver Maple, we have hundreds of markets.'
                : activeStep === 1
                ? 'Enter your asking price and the number of coins you want to sell. If there’s a matching buyer, your order will go through immediately. If not, your listing will stay open until someone meets your price. It’s all in your control.'
                : activeStep === 2
                ? 'Once the sale is confirmed, download your fully insured, prepaid shipping label directly from our site. Just print it out, securely pack your gold, double-box it, and drop it off with one of our trusted partners like UPS or FedEx. No hassle, no worries.'
                : 'As soon as we verify your gold at our facility in Texas, you’ll receive payment via ACH directly to your bank account. It’s fast and reliable, with payments typically arriving in 1-3 business days.'}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              {activeStep === steps.length - 1 ? null : (
                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1 ? '' : 'Next'}
                </Button>
              )}
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default SellProcess;
