import React from 'react';
import './App.css';
import ProductPage from './components/ProductPage/ProductPage';
import IndexPage from './components/IndexPage/IndexPage';
import Onboarding from './components/Onboarding/onboarding';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import AppLayout from './components/AppLayout/AppLayout';
import Balance from './components/Wallet/balance'
import OrdersPage from './components/OrdersPage/OrdersPage';
import OrderDetails from './components/OrdersPage/OrderDetails';
import './assets/css/global.css';
import AddressesPage from './components/AddressesPage/AddressesPage';
import FulfillmentPage from './components/FulfillmentPage/FulfillmentPage'
import OrderRowPage from './components/FulfillmentPage/OrderRow'
import BlogPost from './components/BlogPost/BlogPost'
import ProductWidget from './components/ProductPage/ProductWidget'
import TermsOfService from './components/Tos/TermsOfService'

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={
              <AppLayout>
                <IndexPage />
              </AppLayout>
              } />
            <Route path="/:filter" element={
              <AppLayout>
                <IndexPage />
              </AppLayout>
            } />
            <Route path="/products/:productName" element={
              <AppLayout>
                <ProductPage />
              </AppLayout>
            } />
            {/* New route for partner onboarding */}
            <Route path="/partner/onboarding" element={
              <AppLayout>
                <Onboarding />
              </AppLayout>
            } />
            <Route path="/return/:id" element={
              <AppLayout>
                <Onboarding />
              </AppLayout>
            } />
            <Route path="/onboarding/banking" element={
              <AppLayout>
                <Onboarding />
              </AppLayout>
            } />
            <Route path="/onboarding/tos" element={
              <AppLayout>
                <Onboarding />
              </AppLayout>
            } />
            <Route path="/refresh/:id" element={
              <AppLayout>
                <Onboarding />
              </AppLayout>
            } />
            <Route path="/review/:id" element={
              <AppLayout>
                <Onboarding />
              </AppLayout>
            } />
            <Route path="/withdraw/setup" element={
              <AppLayout>
                <Onboarding />
              </AppLayout>
            } />
            <Route path="/wallet" element={
              <AppLayout>
                <Balance />
              </AppLayout>
            } />
            <Route path="/orders" element={
              <AppLayout>
                <OrdersPage />
              </AppLayout>
            } />
            <Route path="/orders/:trade_uuid"  element={
              <AppLayout>
                <OrderDetails />
              </AppLayout>
            } />
            <Route path="/a/addresses" element={
              <AppLayout>
                <AddressesPage />
              </AppLayout>
            } />
            <Route path="/fulfillment" element={
              <AppLayout>
                <FulfillmentPage />
              </AppLayout>
            } />
            <Route path="/trades/:trade_uuid"  element={
              <AppLayout>
                <OrderRowPage />
              </AppLayout>
            } />
            <Route path="/blog/sell-with-confidence"  element={
              <AppLayout>
                <BlogPost />
              </AppLayout>
            } />
            <Route path="/embed/:slug" element={
              <ProductWidget />
            } />
            <Route path="/legal/tos" element={
              <AppLayout>
                <TermsOfService />
              </AppLayout>
            } />
          </Routes>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;