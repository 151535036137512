import * as React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Check from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

const StepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: ownerState.active ? theme.palette.primary.main : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.completed && {
    color: 'green',
  }),
}));

function StepIcon(props) {
  const { active, completed, className } = props;
  return (
    <StepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? <Check /> : <div className="circle" />}
    </StepIconRoot>
  );
}

export default function Onboarding() {
  const { id } = useParams();
  const location = useLocation();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const baseUrl = window.buildENV === 'staging' ? 'https://stage.goldwaterbullion.com' : 'https://www.goldwaterbullion.com';

  // Steps based on the pathname
  const steps = location.pathname.includes('/withdraw/setup')
    ? [
        {
          label: 'Verify your Identity with Stripe',
          description: `We partner with Stripe to verify identities. Every participant of our marketplace is verified to earn your trust.`,
          button: 'Verify your Identity',
        },
        {
          label: 'Set up with Stripe',
          description: `We partner with Stripe to process transactions. To withdraw funds, please set up a deposit account, even if you've already authorized a payment bank account.`,
          button: 'Add Withdraw Account',
        },
        {
          label: 'Terms of Service',
          description: (
            <>
              Click <a href="/legal/tos" target="_blank" rel="noopener noreferrer">here</a> to view our Terms of Service.
              Accepting these terms is required to continue.
            </>
          ),
          button: 'Accept',
        },
      ]
    : [
        {
          label: 'Verify your Identity with Stripe',
          description: `We partner with Stripe to verify identities. Every participant of our marketplace is verified to earn your trust.`,
          button: 'Verify your Identity',
        },
        {
          label: 'Get Started with Stripe',
          description: `Click the button below to begin connecting your account with Stripe. This connection allows you to sell coins on our platform and ensures your sales proceeds are deposited directly into your account.`,
          button: 'Connect With Stripe',
        },
        {
          label: 'Terms of Service',
          description: (
            <>
              Click <a href="/legal/tos" target="_blank" rel="noopener noreferrer">here</a> to view our Terms of Service.
              Accepting these terms is required to continue.
            </>
          ),
          button: 'Accept',
        },
      ];

  React.useEffect(() => {
    if (location.pathname.includes('/review/setup')) {
      // All steps completed
      setActiveStep(steps.length);
    } else if (location.pathname.includes('/return/')) {
      // Returned from Stripe Connect
      // tos final step
      setActiveStep(2);
    } else if (location.pathname.includes('/onboarding/tos')) {
      // Returned from Stripe Connect
      // tos final step
      setActiveStep(2);
    } else if (location.pathname.includes('/refresh/') || location.pathname.includes('/onboarding/banking')) {
      // Returned from Identity Verification
      setActiveStep(1);
    } else if (
      location.pathname.includes('/partner/onboarding') ||
      location.pathname.includes('/withdraw/setup')
    ) {
      // Starting point
      setActiveStep(0);
    } else {
      // Default case
      setActiveStep(0);
    }
  }, [location.pathname, steps.length]);

  const fetchStripeConnectId = async () => {
    setLoading(true);
    const isLocal = process.env.REACT_APP_LOCAL === 'true';

    if (isLocal) {
      try {
        const fixtureData = await import('./fixtures/stripe_connect_id.json');
        await createAccountLinkAndRedirect(fixtureData.stripe_connect_id);
      } catch (err) {
        setError('Error loading fixture data');
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const response = await fetch('/api/banking/connect');
        const data = await response.json();
        if (response.ok) {
          await createAccountLinkAndRedirect(data.stripe_connect_id);
        } else {
          throw new Error(data.message || 'Login before you continue');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const postTosAcceptance = async () => {
    setLoading(true);
    const isLocal = process.env.REACT_APP_LOCAL === 'true';
  
    if (isLocal) {
      try {
        const fixtureData = await import('./fixtures/tos_accepted.json');
        console.log('Mock TOS acceptance:', fixtureData);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (err) {
        setError('Error loading fixture data');
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const response = await fetch('/tos/accepted', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ accepted: true }),
        });
        if (!response.ok) {
          throw new Error('Failed to accept Terms of Service');
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  };
  
  const verification_id = async () => {
    setLoading(true);
    const isLocal = process.env.REACT_APP_LOCAL === 'true';
    if (isLocal) {
      try {
        await createVerificationFlowLinkAndRedirect();
      } catch (err) {
        setError('Error loading fixture data');
      } finally {
        setLoading(false);
      }
    } else {
      try {
        await createVerificationFlowLinkAndRedirect();
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const createAccountLinkAndRedirect = async (connectId) => {
    const isLocal = process.env.REACT_APP_LOCAL === 'true';

    if (isLocal) {
      try {
        const fixtureData = await import('./fixtures/account_link.json');
        window.location.href = fixtureData.url;
      } catch (err) {
        setError('Error loading fixture data');
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const response = await fetch('/account_link', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ account: connectId }),
        });
        const data = await response.json();
        if (response.ok) {
          window.location.href = data.url;
        } else {
          throw new Error(data.error || 'Failed to create account link');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const createVerificationFlowLinkAndRedirect = async () => {
    const isLocal = process.env.REACT_APP_LOCAL === 'true';

    if (isLocal) {
      try {
        const fixtureData = await import('./fixtures/verification_flow.json');
        window.location.href = fixtureData.url;
      } catch (err) {
        setError('Error loading fixture data');
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const response = await fetch('/verification_flow', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ verification_flow_id: 'YOUR_VERIFICATION_FLOW_ID' }),
        });
        const data = await response.json();
        if (response.ok) {
          window.location.href = data.url;
        } else {
          throw new Error(data.error || 'Failed to create verification link');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleNext = () => {
    if (activeStep === 0) {
      verification_id(); // Trigger the verification flow
    } else if (activeStep === 1) {
      fetchStripeConnectId(); // Trigger the Stripe connection
    } else if (activeStep === 2) {
      // Handle Terms of Service acceptance
      postTosAcceptance()
      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1); // Proceed to the next step
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setLoading(false);
    setActiveStep(0);
  };

  return (
    <Container sx={{ mt: 8 }}>
      <Grid container spacing={2}>
        {/* Stepper Section */}
        <Grid item xs={12} sm={12} md={6}>
          <Box sx={{ maxWidth: 400 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    StepIconComponent={StepIcon} // Use the custom StepIcon here
                    optional={
                      index === steps.length - 1 ? (
                        <Typography variant="caption">Last step</Typography>
                      ) : null
                    }
                  >
                    {step.label}
                  </StepLabel>
                  <StepContent align="left">
                    <Typography>{step.description}</Typography>
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                          disabled={loading}
                          startIcon={loading && <CircularProgress size={20} />}
                        >
                          {step.button}
                        </Button>
                      </div>
                    </Box>
                    {error && <Typography color="error">{error}</Typography>}
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep >= steps.length && (
              <Paper square elevation={0} sx={{ p: 3 }}>
                <Typography>All steps completed - you&apos;re finished</Typography>
                <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                  Reset
                </Button>
                <Button
                  onClick={() => (window.location.href = `${baseUrl}`)}
                  sx={{ mt: 1, mr: 1 }}
                >
                  Go to Homepage
                </Button>
              </Paper>
            )}
          </Box>
        </Grid>

        {/* Image Section */}
        <Grid item xs={12} sm={12} md={6}>
          <Box sx={{ maxWidth: '100%', maxHeight: 400 }}>
            <a
              href={activeStep === 0 ? "https://slideby.s3.us-west-1.amazonaws.com/verify.png" : "https://s3.us-west-1.amazonaws.com/jz.io/connect.png"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={activeStep === 0 ? "https://slideby.s3.us-west-1.amazonaws.com/verify.png" : "https://s3.us-west-1.amazonaws.com/jz.io/connect.png"}
                alt={activeStep === 0 ? "Verify" : "Connect"}
                style={{ height: 'auto', maxHeight: '400px' }}
              />
            </a>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
