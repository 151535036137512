import React from 'react';
import { Grid, Typography, IconButton, Button, Container, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const FundAccountPreview = ({ selectedPaymentMethod, userPaymentMethods, amount, handleBack, handleConfirm, isButtonDisabled }) => {
    const selectedMethod = userPaymentMethods.find(method => method.id === selectedPaymentMethod);

    return (
        <Container>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        <IconButton onClick={handleBack} color="primary" aria-label="go back">
                            <ArrowBackIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="h6" color="text.primary" gutterBottom sx={{ textAlign: 'left' }}>
                            Confirm
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                            To:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom align="right">
                            USD Wallet
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                            From:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom align="right">
                            {selectedMethod ? `${selectedMethod.bank_name} (****${selectedMethod.last4})` : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                            Funds Will Arrive:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom align="right">
                            2-3 Days
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                            Goldwater Bullion Exchange Fee:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom align="right">
                            $0.00
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                            Total:
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom align="right">
                            ${parseFloat(amount).toFixed(2)}
                        </Typography>
                    </Grid>
                </Grid>
                <Button
                    onClick={handleConfirm}
                    variant="contained"
                    color="primary"
                    disabled={isButtonDisabled}
                    sx={{ mt: 2 }}
                    fullWidth
                >
                    Add Funds
                </Button>
            </Box>
        </Container>
    );
};

export default FundAccountPreview;
