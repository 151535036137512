import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useMediaQuery from '@mui/material/useMediaQuery';

const HeroBanner = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)'); // Check if the screen width is less than 600px (typical mobile size)

  return (
    <div style={{ position: 'relative', cursor: 'pointer' }} onClick={() => navigate('/blog/sell-with-confidence')}>
      <img
        src="https://slideby.s3.us-west-1.amazonaws.com/hero.jpg"
        alt="Promotion"
        style={{ width: '100%' }}
      />

      <Button
        variant="contained"
        color="primary"
        sx={{
          position: 'absolute',
          bottom: '10%', // 10% from the bottom
          left: '50%',
          transform: 'translateX(-50%)', // Center horizontally
        }}
        size="large"
        endIcon={<ArrowForwardIcon />}
      >
        {isMobile ? 'Sell Gold' : 'Buy & Sell Gold with Free Insured Shipping'}
      </Button>
    </div>
  );
};

export default HeroBanner;
