import React from 'react';
import { Box, Typography, Button, Container } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const WithdrawConfirmationScreen = ({ onClose }) => {
    return (
        <Container>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '80vh',
                    textAlign: 'center',
                }}
            >
                <CheckCircleIcon color="primary" sx={{ fontSize: 80, mb: 2 }} />
                <Typography variant="h5" color="text.primary" gutterBottom>
                    Your transfer request was sent
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
                    We’ll notify you when your transfer is complete. You can also check your account for updates.
                </Typography>
                <Button
                    onClick={onClose}
                    variant="contained"
                    color="primary"
                    sx={{ width: '100%' }}
                >
                    View Account
                </Button>
            </Box>
        </Container>
    );
};

export default WithdrawConfirmationScreen;
