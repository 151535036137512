import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import ProductCard from './ProductCard';
import HeroBanner from './HeroBanner';
import { Button, Modal, Box, Slider, Badge, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import './ProductFilter.css';
import { SignalWifiStatusbarNullTwoTone } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import 'boxicons';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const weightMarks = [
  { value: 0, label: '1/10 oz' },
  { value: 25, label: '1/4 oz' },
  { value: 50, label: '1/2 oz' },
  { value: 75, label: '1 oz' },
  { value: 90, label: '5 oz' },
  { value: 100, label: '1 kg' }
];

const GOLD_PURITIES = [
  0.9999, 0.999, 0.99,
  0.9167, 0.9
];

const scaleValueToOunces = (value) => {
  if (value <= 25) return 0.1 + (value / 25) * 0.15;
  if (value <= 50) return 0.25 + ((value - 25) / 25) * 0.25;
  if (value <= 75) return 0.5 + ((value - 50) / 25) * 0.5;
  if (value <= 90) return 1 + ((value - 75) / 15) * 4;
  return 5 + ((value - 90) / 10) * 27.15;
};

const cacheProductsInLocalStorage = (products) => {
  products.forEach(product => {
    const { product_id, title, image_one_thumb } = product;
    const cachedProduct = { title, thumbnail: image_one_thumb };
    localStorage.setItem(product_id, JSON.stringify(cachedProduct));
  });
};

const mints = [
  "United States Mint",
  "Royal Canadian Mint",
  "Perth Mint",
  "Royal Mint",
  "Austrian Mint",
  "China Mint",
  "South African Mint",
  "Mexican Mint",
  "Swiss Mint",
  "Japan Mint",
  "Monnaie de Paris",
  "New Zealand Mint",
  "Royal Australian Mint",
  "Korean Mint (KOMSCO)",
  "Russian Mint (Goznak)",
  "Royal Dutch Mint",
  "Italian Mint",
  "Central Bank of Brazil",
  "Spanish Royal Mint",
  "PAMP Suisse",
  "Valcambi Suisse",
  "Credit Suisse",
  "Johnson Matthey",
  "Engelhard",
  "Heraeus",
  "Sunshine Minting",
  "Scottsdale Mint",
  "Asahi Refining",
  "Kitco",
  "Elemetal Mint",
  "Argor-Heraeus",
  "Mitsubishi Materials Corporation",
  "Metalor Technologies",
  "Istanbul Gold Refinery",
  "Republic Metals Corporation",
  "Bavarian State Mint",
  "Casa de Moneda de Colombia",
  "Royal Belgian Mint",
  "The Royal Mint of Norway",
  "Hungarian Mint",
  "Royal Mint of Denmark",
  "Greek Mint",
  "Finnish Mint",
  "Polish Mint",
  "Bulgarian Mint",
  "Generic"
];

const ProductFilter = () => {
  const query = useQuery();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [weightRange, setWeightRange] = useState([0, 100]);
  const [activeFilters, setActiveFilters] = useState(0);
  const [selectedMint, setSelectedMint] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const isLocal = process.env.REACT_APP_LOCAL === 'true';
  const [selectedPurity, setSelectedPurity] = useState(''); // New state for purity


  const getActiveFiltersCount = (params) => {
    let count = 0;
    params.forEach((value, key) => {
      if (value !== null && value !== '' && value !== 'null') {
        count++;
      }
    });
    return count;
  };

  const fetchProducts = useCallback(async (queryString) => {
    if (isLocal) {
      try {
        const staticResponse = await import('./fixtures/products.json');
        setProducts(staticResponse.default);
        cacheProductsInLocalStorage(staticResponse.default);
      } catch (error) {
        console.error('Error loading static data:', error);
        setError('Error loading static data');
      } finally {
        setLoading(false);
      }
    } else {
      const apiUrl = `/api/products${queryString}`;
      try {
        const response = await axios.get(apiUrl);
        setProducts(response.data);
        cacheProductsInLocalStorage(response.data);
      } catch (error) {
        console.error('Error fetching product info:', error);
        setError('Error fetching product info');
      } finally {
        setLoading(false);
      }
    }
  }, [isLocal]);

  useEffect(() => {
    fetchProducts(location.search);
  }, [fetchProducts, location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const count = getActiveFiltersCount(queryParams);
    setActiveFilters(count);
  }, [location.search]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    
    const manufacturer = queryParams.get('manufacturer') || '';
    const purity = queryParams.get('purity') || '';
  
    setSelectedMint(manufacturer);
    setSelectedPurity(purity);
  }, [location.search]);

  const updateQueryString = useCallback((updates) => {
    const newQuery = new URLSearchParams(location.search);
  
    Object.entries(updates).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        newQuery.set(key, value);
      } else {
        newQuery.delete(key);
      }
    });
  
    navigate({ search: `?${newQuery.toString()}` }, { replace: true });
  }, [navigate, location.search]);
  

  const handleWeightChange = (event, newValue) => {
    setWeightRange(newValue);
  };


  const handlePurityChange = (event) => {
    const value = event.target.value;
    setSelectedPurity(value);
  
    if (value === "") {
      updateQueryString({ purity: null }); // Remove the purity filter
    }
  };
  

  const applyFilters = () => {
    const minWeight = scaleValueToOunces(weightRange[0]);
    const maxWeight = scaleValueToOunces(weightRange[1]);
  
    const weightFilters = {};
    const purityFilter = selectedPurity ? { purity: selectedPurity } : { purity: null }; // Handle 'None' selection
  
    if (weightRange[0] !== 0) weightFilters.min_weight = minWeight;
    if (weightRange[1] !== 100) weightFilters.max_weight = maxWeight;
  
    if (weightRange[0] === 0 && weightRange[1] === 100) {
      weightFilters.min_weight = null;
      weightFilters.max_weight = null;
    }
  
    updateQueryString({ ...weightFilters, ...purityFilter }); // Include purity in query
    setFilterModalOpen(false);
  };
  
  const handleMintChange = (event) => {
    const value = event.target.value;
    setSelectedMint(value);
  
    if (value === "") {
      updateQueryString({ manufacturer: null });
    } else {
      updateQueryString({ manufacturer: value });
    }
  };
  

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <HeroBanner />
      <div className="filter-buttons">
        <div className="filter-button">
            <IconButton
              onClick={() => updateQueryString({ issuing_country: 'United States', material: null, manufacturer: null, purity: null })}
              aria-label="USA"
              size="large"
            >
            <box-icon name='coin-stack' type='solid' ></box-icon>
            </IconButton>
            <Typography variant="caption" color="text.secondary">
              U.S. Coins
            </Typography>
          </div>
        <div className="filter-button">
          <IconButton
            onClick={() => updateQueryString({ issuing_country: 'Canada', material: null, manufacturer: null, purity: null })}
            aria-label="Canada"
            size="large"
          >
          <box-icon name="coin-stack"></box-icon>
          </IconButton>
          <Typography variant="caption" color="text.secondary">
            Canadian Coins
          </Typography>
        </div>
        <div className="filter-button">
          <IconButton
            onClick={() => updateQueryString({ material: 'Gold', manufacturer: null, issuing_country: null, purity: null })}
            aria-label="Gold"
            size="large"
          >
          <box-icon name="dollar"></box-icon>
          </IconButton>
          <Typography variant="caption" color="text.secondary">
            Gold Coins
          </Typography>
        </div>
        <div className="filter-button">
          <IconButton
            onClick={() => updateQueryString({ material: 'Silver', manufacturer: null, issuing_country: null, purity: null })}
            aria-label="Gold"
            size="large"
          >
            <box-icon name="coin"></box-icon>
          </IconButton>
          <Typography variant="caption" color="text.secondary">
            Silver Coins
          </Typography>
        </div>
        <div className="filter-button">
          <IconButton
            onClick={() => updateQueryString({ manufacturer: 'Swiss Mint', issuing_country: null, material: null, purity: null })}
            aria-label="Swiss Mint"
            size="large"
          >
            <box-icon name='cable-car' ></box-icon>
          </IconButton>
          <Typography variant="caption" color="text.secondary">
            Swiss Mint
          </Typography>
        </div>
        <div className="filter-button">
          <IconButton
            onClick={() => {
              setSelectedPurity(0.9999);
              updateQueryString({
                purity: 0.9999,
                manufacturer: null,
                issuing_country: null,
                material: 'Gold',
              });
            }}
            aria-label="Fine Gold"
            size="large"
          >
          <box-icon name='diamond'></box-icon>
          </IconButton>
          <Typography variant="caption" color="text.secondary">
            Fine Gold
          </Typography>
        </div>
        <div className="filter-button">
          <IconButton
              onClick={() => {
                setSelectedPurity('');
                updateQueryString({
                  min_weight: null,
                  max_weight: null,
                  issuing_country: null,
                  material: null,
                  manufacturer: null,
                  purity: null,
                });
              }}
              aria-label="All"
              size="large"
            >
            <box-icon name='infinite'></box-icon>
          </IconButton>
          <Typography variant="caption" color="text.secondary">
            Clear
          </Typography>
        </div>
        <div className="filter-button">
          <Badge badgeContent={activeFilters} color="primary">
            <IconButton onClick={() => setFilterModalOpen(true)} aria-label="Filters" size="large">
              <box-icon name='filter' ></box-icon>
            </IconButton>
          </Badge>
          <Typography variant="caption" color="text.secondary">
            More Filters
          </Typography>
        </div>
      </div>

      <Grid container spacing={2} justifyContent="center">
        {products.map((product) => (
          <Grid item key={product.product_id} sx={{ transition: 'all 0.3s ease' }}>
            <ProductCard product={product} />
          </Grid>
        ))}
      </Grid>

      <Modal 
  open={filterModalOpen} 
  onClose={() => setFilterModalOpen(false)}
  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} // Center the modal
>
  <Box
    sx={{
      position: 'relative',  // Ensure the close button is positioned relative to this Box
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      borderRadius: 2,
      p: 4,  // Padding inside the modal
    }}
  >
    {/* Close Icon */}
    <IconButton
      aria-label="close"
      onClick={() => setFilterModalOpen(false)}
      sx={{
        position: 'absolute',
        top: 8,
        right: 8,
      }}
    >
      <CloseIcon />
    </IconButton>

    <Typography variant="h5" gutterBottom>
      Filters
    </Typography>
    <Divider />
    <Typography variant="body1" sx={{ marginTop: 2 }} gutterBottom>
      Filter by Coin Weight
    </Typography>
    <Slider
      value={weightRange}
      onChange={handleWeightChange}
      valueLabelDisplay="off"
      step={1}
      marks={weightMarks}
      min={0}
      max={100}
      sx={{
        '& .MuiSlider-mark': {
          marginLeft: '-10px',
          marginRight: '-10px',
        },
        '& .MuiSlider-markLabel': {
          transform: 'translateX(-10%)',
        },
      }}
    />
    <Divider />
    <Typography variant="body1" sx={{ marginTop: 2 }} gutterBottom>
      Producers
    </Typography>
    <FormControl fullWidth sx={{ marginTop: 2 }}>
      <InputLabel id="mint-select-label">Select Mint</InputLabel>
      <Select
        labelId="mint-select-label"
        value={selectedMint}
        onChange={handleMintChange}
      >
        <MenuItem value="">None</MenuItem>
        {mints.map((mint) => (
          <MenuItem key={mint} value={mint}>
            {mint}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    <Divider />
    <Typography variant="body1" sx={{ mt: 2 }} gutterBottom>
      Filter by Purity
    </Typography>
    <FormControl fullWidth sx={{ marginTop: 2 }}>
      <InputLabel id="purity-select-label">Select Purity</InputLabel>
      <Select
        labelId="purity-select-label"
        value={selectedPurity}
        onChange={handlePurityChange}
      >
        <MenuItem value="">None</MenuItem> {/* Add a default empty option */}
        {GOLD_PURITIES.map((purity) => (
          <MenuItem key={purity} value={purity.toString()}>
            {(purity * 100).toFixed(2)}%
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    <Button variant="contained" onClick={applyFilters} sx={{ marginTop: 2 }}>
      Apply Filter
    </Button>
  </Box>
</Modal>

    </div>
  );
};

export default ProductFilter;
