import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Box, Typography, Button, Stack, Snackbar, Alert, Paper, Tooltip, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import EmptyOrders from './EmptyOrders'; 

const OrderBox = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[2],
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const OrderItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  boxShadow: theme.shadows[1],
  borderRadius: theme.shape.borderRadius,
}));

const OrderDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}));

const OrderActions = styled(Stack)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const OrderHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(1.5),
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  borderBottom: `1px solid ${theme.palette.divider}`,
  marginBottom: theme.spacing(1.5),
}));

const OrdersPage = ({ marketId }) => {
  const { auth } = useAuth();
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState({});
  const [productNames, setProductNames] = useState({});
  const [productSlugs, setProductSlugs] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const isLocal = process.env.REACT_APP_LOCAL === 'true';
  const navigate = useNavigate();
  const baseUrl = window.buildENV === 'staging' ? 'https://stage.goldwaterbullion.com' : 'https://www.goldwaterbullion.com';

  useEffect(() => {
    const fetchOrders = async () => {
      let fetchedOrders = [];

      if (isLocal) {
        try {
          const staticData = await import('./fixtures/orders.json');
          fetchedOrders = staticData.default;
        } catch (error) {
          console.error("Error loading static orders:", error);
        }
      } else {
        try {
          const response = await axios.get(`/api/orders`);
          fetchedOrders = response.data;
        } catch (error) {
          console.error("Error fetching orders:", error);
        }
      }

      if (fetchedOrders && Array.isArray(fetchedOrders)) {
        const filteredOrders = fetchedOrders.filter(order =>
          order.transaction_status === 'cleared'
        );
        
        setOrders(filteredOrders);
        fetchProductDetails(filteredOrders);
      } else {
        console.error("Error: fetchedOrders is not an array or is undefined");
      }
    };

    const fetchProductDetails = async (orders) => {
      const productImages = {};
      const productNames = {};
      const productSlugs = {};
      await Promise.all(
        orders.map(async (order) => {
          try {
            let response;
            if (isLocal) {
              response = await import('./fixtures/productImage.json');
              productImages[order.product_id] = response.img;
              productNames[order.product_id] = response.title;
              productSlugs[order.product_id] = response.slug;
            } else {
              response = await axios.get(`/api/products/uuid/${order.product_id}`);
              productImages[order.product_id] = response.data.img;
              productNames[order.product_id] = response.data.title;
              productSlugs[order.product_id] = response.data.slug;
            }
          } catch (error) {
            console.error(`Error fetching product details for product ID: ${order.product_id}`, error);
          }
        })
      );
      setProducts(productImages);
      setProductNames(productNames);
      setProductSlugs(productSlugs)
    };

    if (auth.user) {
      fetchOrders();
    }
  }, [auth.user, marketId, isLocal]);
  
  const renderShipmentStatus = (shipment) => {
    if (shipment) {
      return (
        <Typography variant="h6" gutterBottom>
          Shipment Status: {shipment.status}
        </Typography>
      );
    } else {
      return (
        <Typography variant="h6" gutterBottom>
          Preparing for shipment
        </Typography>
      );
    }
  };

  const renderOrderDetailsTooltip = (order) => {
    const { order: orderDetails } = order;
    if (orderDetails) {
      return (
        <Tooltip
          title={
            <>
              <Typography>Bid ID: {orderDetails.uuid}</Typography>
              <Typography>Bid: ${(orderDetails.bid / 100).toFixed(2)}</Typography> 
              <Typography>Volume: {orderDetails.volume}</Typography>
              <Typography>Created At: {new Date(orderDetails.created_at).toLocaleString()}</Typography>
            </>
          }
        >
          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <Typography variant="body2" color="primary">
              {orderDetails.uuid}
            </Typography>
            <KeyboardArrowDownIcon fontSize="small" color="primary" sx={{ marginLeft: 1 }} />
          </Box>
        </Tooltip>
      );
    }
    return <Typography variant="body2">N/A</Typography>;
  };
  


  const renderShipToTooltip = (order) => {  
    const handleAddress = () => {
      navigate('/a/addresses');
    };
  
    const { address } = order;
  
    if (address) {
      return (
        <Tooltip
          title={
            <>
              <Typography>{address.name}</Typography>
              <Typography>{address.street1}</Typography>
              <Typography>{`${address.city}, ${address.state} ${address.zip}`}</Typography>
              <Typography>{address.phone}</Typography>
              <Typography>{address.email}</Typography>
            </>
          }
        >
          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <Typography variant="body2" color="primary">
              {address.name}
            </Typography>
            <KeyboardArrowDownIcon fontSize="small" color="primary" sx={{ marginLeft: 1 }} />
          </Box>
        </Tooltip>
      );
    }
  
    return (
      <Typography
        variant="body2"
        color="primary"
        sx={{ cursor: 'pointer' }}
        onClick={handleAddress}
      >
        Verify Shipment Address
      </Typography>
    );
  };
  
  if (orders.length === 0) {
    return <EmptyOrders />;
  }

  return (
    <Container>
      <Box sx={{ margin: 3 }}>
        {orders.map(order => (
          <OrderBox key={order.transaction_id}>
            <OrderHeader>
              <Box>
                <Typography variant="body2" color="textSecondary">TRADE EXECUTED</Typography>
                <Typography variant="body2">{new Date(order.created_at).toLocaleDateString()}</Typography>
              </Box>
              <Box>
                <Typography variant="body2" color="textSecondary">TOTAL</Typography>
                <Typography variant="body2">${(order.amount / 100).toFixed(2)}</Typography>
              </Box>
              <Box>
                <Typography variant="body2" color="textSecondary">SHIP TO</Typography>
                {renderShipToTooltip(order)}
              </Box>
              <Box>
                <Typography variant="body2" color="textSecondary">BID ID</Typography>
                  <Link href={`/orders/${order.trade_uuid}`} rel="noopener noreferrer">
                    {renderOrderDetailsTooltip(order)}
                  </Link>
              </Box>
            </OrderHeader>
            <OrderItem>
            <OrderDetails>
              {renderShipmentStatus(order.shipment)}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {products[order.product_id] ? (
                    <img
                      src={products[order.product_id]}
                      alt={productNames[order.product_id]}
                      style={{ maxWidth: '100px', marginRight: '16px', borderRadius: '4px' }}
                    />
                  ) : (
                    <Typography variant="body2" color="textSecondary" style={{ marginRight: '16px' }}>
                      Loading image...
                    </Typography>
                  )}
                  <div>
                    <Typography variant="body1" gutterBottom>
                      <Link href={`${baseUrl}/products/${productSlugs[order.product_id] || ''}`} rel="noopener noreferrer">
                        {productNames[order.product_id] || 'Product Name'}
                      </Link>
                    </Typography>
                    <Typography variant="body2" sx={{alignItems: 'flex-start'}} gutterBottom align="left">
                      Items in shipment: {order.volume}
                    </Typography>
                  </div>
                </div>
              </OrderDetails>
              <OrderActions direction="column" spacing={1}>
                {order.shipment?.tracking_number && order.shipment?.carrier && (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ textTransform: 'none', width: '200px', backgroundColor: '#FFD700', color: '#000', '&:hover': { backgroundColor: '#FFC107' } }}
                    onClick={() => window.open(`https://www.google.com/search?q=${order.shipment.carrier}+tracking+ID+${order.shipment.tracking_number}`, '_blank')}
                  >
                    Track Package
                  </Button>
                )}
                {/* <Button
                  variant="contained"
                  sx={{ textTransform: 'none', width: '200px', backgroundColor: '#FFD700', color: '#000', '&:hover': { backgroundColor: '#FFC107' } }}
                >
                  Problem with Order
                </Button> */}
              </OrderActions>
            </OrderItem>
          </OrderBox>
        ))}

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity="success">
            Order cancelled successfully!
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default OrdersPage;
