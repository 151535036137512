import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
  const [trendingMarkets, setTrendingMarkets] = useState([]);
  const isLocal = process.env.REACT_APP_LOCAL === 'true';

  // Fetch trending markets data
  const fetchTrendingMarkets = async () => {
    try {
      let marketsData;
      if (isLocal) {
        const staticData = await import('./fixtures/trendingMarkets.json');
        marketsData = staticData.default.trending_products || []; // Handle potential undefined
      } else {
        const response = await fetch('/api/trending-markets');
        if (!response.ok) {
          throw new Error('Error fetching trending markets');
        }
        
        // Ensure the response is not empty
        const data = (await response.json()) || {};
        marketsData = data.trending_products || []; // Safely access the array
      }
  
      // Ensure the data is an array
      setTrendingMarkets(Array.isArray(marketsData) ? marketsData : []);
    } catch (error) {
      console.error('Error fetching trending markets:', error);
      setTrendingMarkets([]); // Handle errors gracefully
    }
  };
  
    

  useEffect(() => {
    fetchTrendingMarkets();
  }, []);

  return (
    <Box component="footer" sx={{ p: 4, bgcolor: 'background.paper', borderTop: 1, borderColor: 'divider' }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Goldwater Bullion Exchange Section */}
          <Grid item xs={12} sm={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                Goldwater Bullion Exchange
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2, textAlign: 'left', lineHeight: 1.6 }}>
                Trusted bullion trading platform ensuring secure and transparent transactions for all your gold and silver investments.
              </Typography>
              <Box sx={{ display: 'flex', mt: 2 }}>
                <Link href="https://x.com/GoldwaterBXinc" color="inherit" sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                  <XIcon />
                </Link>
                <Link href="https://www.linkedin.com/company/goldwater-bullion-exchange-inc" color="inherit" sx={{ ml: 2, textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                  <LinkedInIcon />
                </Link>
              </Box>
            </Box>
          </Grid>

          {/* Trending Markets Section */}
          <Grid item xs={12} sm={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                Trending Markets
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 0.5, width: '100%' }}>
                {trendingMarkets.length > 0 ? (
                  trendingMarkets.map((market) => (
                    <Link
                      key={market.slug}
                      href={`/products/${market.slug}`}
                      color="inherit"
                      sx={{
                        textDecoration: 'none',
                        width: '100%',
                        '&:hover': { textDecoration: 'underline' },
                      }}
                    >
                      <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'left' }}>
                        {market.name}
                      </Typography>
                    </Link>
                  ))
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    No trending markets available.
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>


          {/* Shop by Concern Section */}
          <Grid item xs={12} sm={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                Shop by Concern
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant="body2" color="text.secondary">Gold Bug</Typography>
                <Typography variant="body2" color="text.secondary">Hedge Inflation</Typography>
                <Typography variant="body2" color="text.secondary">Silver Squeeze</Typography>
                <Typography variant="body2" color="text.secondary">Diversify Portfolio</Typography>
                <Typography variant="body2" color="text.secondary">Safe Haven Asset</Typography>
              </Box>
            </Box>
          </Grid>

          {/* More about Goldwater Section */}
          <Grid item xs={12} sm={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                More about Goldwater
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Link href="/blog/sell-with-confidence" color="inherit" sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                  <Typography variant="body2" color="text.secondary">Our Story</Typography>
                </Link>
                <Link href="/legal/tos" color="inherit" sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                  <Typography variant="body2" color="text.secondary">Terms of service</Typography>
                </Link>
                <Typography variant="body2" color="text.secondary">Customer Service</Typography>
                <Typography variant="body2" color="text.secondary">My Account</Typography>
                <Link href="/orders" color="inherit" sx={{ textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}>
                  <Typography variant="body2" color="text.secondary">My Fills</Typography>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {/* Footer Bottom Section */}
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography variant="body2" color="text.secondary">
            © Goldwater Bullion Exchange, Inc. All rights reserved.
          </Typography>
          <Typography variant="body2" color="text.secondary">
            5900 Balcones Drive, STE 100 Austin, TX 78731
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
