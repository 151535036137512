import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as solidStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as regularStar } from '@fortawesome/free-regular-svg-icons';

const ProductCard = React.memo(({ product }) => {
  const [favorited, setFavorited] = React.useState(false);
  const [hovered, setHovered] = React.useState(false);

  const UNIT_ABBREVIATIONS = {
    "Gram": "g",
    "Troy Ounce": "troy oz",
    "Kilogram": "kg",
    "Milligram": "mg",
    "Pennyweight": "dwt",
    "Tael": "tael",
    "Tola": "tola",
    "Mace": "mace"
  };
  
  const handleFavoriteClick = () => {
    setFavorited(!favorited);
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const getSpecialTag = () => {
    if (product.title.includes('Roman Double Eagle')) {
      return 'Historical Significance';
    } else if (product.title.includes('Buffalo')) {
      return 'Popular in Collection';
    } else {
      return 'Rare Item';
    }
  };

  const handleCardClick = () => {
    window.location.href = `/products/${product.slug}`;
  };

  return (
    <Card
      sx={{
        maxWidth: 220,
        maxHeight: hovered ? 500 : 320,  // Expand vertically on hover
        minHeight: 300,
        transition: 'max-height 0.3s ease',  // Smooth expand/shrink vertically
        cursor: 'pointer',
        margin: 2,
        position: 'relative',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleCardClick}  // Navigate on card click
    >
      <CardMedia
        sx={{ height: 200, position: 'relative', cursor: 'pointer' }}
        title={product.title}
        onClick={handleCardClick}  // Navigate on image click
      >
        {hovered && product.video ? (
          <video
            src={product.video}
            width="100%"
            height="100%"
            autoPlay
            muted
            loop
            style={{ objectFit: 'cover' }}
          />
        ) : (
          <img
            src={
              product.image_one_thumb && product.image_one_thumb.length > 0
                ? product.image_one_thumb
                : 'https://slideby.s3.us-west-1.amazonaws.com/placeholder-image.png'
            }
            alt={product.title}
            width="100%"
            height="100%"
            style={{ objectFit: 'contain', width: '100%', height: '100%' }}
          />
        )}
        <IconButton
          sx={{ position: 'absolute', top: 8, right: 8, color: 'white', backgroundColor: 'rgba(0,0,0,0.1)' }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent navigating when clicking the star
            handleFavoriteClick();
          }}
        >
          <FontAwesomeIcon icon={favorited ? solidStar : regularStar} />
        </IconButton>
      </CardMedia>

      <CardContent sx={{ padding: 1, overflow: 'hidden' }}>
        <Typography gutterBottom variant="caption" component="div" align="left" noWrap>
          {product.manufacturer}
        </Typography>
        <Typography gutterBottom variant="body2" component="div" align="left" noWrap>
          {product.title}
        </Typography>

        {/* Stack for multiple chips */}
        <Stack direction="row" spacing={1} sx={{ marginTop: 1, flexWrap: 'wrap' }}>
          {product.weight && (
            <Chip label={`${product.weight} ${UNIT_ABBREVIATIONS[product.units] || 'units'}`} size="small" color="primary" />
          )}
          {product.purity && <Chip label={`Purity: ${product.purity}`} size="small" color="success" variant="outlined" />}
        </Stack>
        <Stack direction="row" spacing={1} sx={{ marginTop: 1, flexWrap: 'wrap' }}>
          <Chip label={getSpecialTag()} size="small" color="warning" variant="outlined" />
        </Stack>
      </CardContent>

      {/* Button only visible on hover */}
      <CardActions
        sx={{
          justifyContent: 'center', // Center the button
          padding: 1,
          width: '93%', // Full width button
          transition: 'max-height 0.3s ease',  // Smoothly appear and disappear
          overflow: 'hidden',
          maxHeight: hovered ? '40px' : '0px',  // Show button only when hovered
        }}
      >
        {hovered && (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.stopPropagation();  // Prevent card navigation on button click
              window.location.href = `/products/${product.slug}`;  // Navigate to the product page
            }}
            fullWidth
            sx={{
              padding: '8px 16px',  // Adjust the padding as needed
            }}
          >
            Make Bid
          </Button>
        )}
      </CardActions>
    </Card>
  );
});

export default ProductCard;
