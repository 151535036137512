import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({ user: null, loading: true });

    useEffect(() => {
        const checkAuth = async () => {
            const isLocal = process.env.REACT_APP_LOCAL === 'true';
            
            if (isLocal) {
                try {
                    const staticResponse = await import('./fixtures/auth-fixture.json');
                    setAuth({ user: staticResponse.user, loading: false });
                } catch (error) {
                    console.error("Error loading static auth data:", error);
                    setAuth({ user: null, loading: false });
                }
            } else {
                try {
                    const response = await axios.get('/api/check-auth');
                    setAuth({ user: response.data.user, loading: false });
                } catch (error) {
                    setAuth({ user: null, loading: false });
                }
            }
        };

        checkAuth();
    }, []);

    const login = async (credentials) => {
        const response = await axios.post('/api/login', credentials);
        setAuth({ user: response.data.user, loading: false });
    };

    const logout = async () => {
        await axios.post('/api/logout');
        setAuth({ user: null, loading: false });
    };

    return (
        <AuthContext.Provider value={{ auth, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
