import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';

const PaymentMethodsSelector = ({ userPaymentMethods, selectedPaymentMethod, setSelectedPaymentMethod, amount, setAmount }) => {
    return (
        <>
            <FormControl fullWidth margin="normal">
                <InputLabel id="payment-method-label">Payment Method</InputLabel>
                <Select
                    labelId="payment-method-label"
                    value={selectedPaymentMethod}
                    onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                    label="Payment Method"
                >
                    {userPaymentMethods.map((method) => (
                        <MenuItem key={method.id} value={method.id}>
                            {`${method.bank_name} (****${method.last4})`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <TextField
                label="Amount"
                fullWidth
                margin="normal"
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
            />
        </>
    );
};

export default PaymentMethodsSelector;
