import React, { useEffect, useRef } from 'react';
import { 
  Box, Typography, Avatar, CircularProgress, TextField, Button, Chip, Modal, Paper 
} from '@mui/material';
import goldBarImage from './clippy.png';
import LaunchIcon from '@mui/icons-material/Launch'; // Import Launch icon
import FundingForm from '../IndexPage/FundAccount'; // Import FundingForm

const ChatView = ({
  chatLog,
  loading,
  customQuestion,
  setCustomQuestion,
  handleCustomQuestionSubmit,
  gravatarUrl,
  auth,
  showFundAccountModal,
  setShowFundAccountModal,
}) => {
  const chatBoxRef = useRef(null);
  const isLoggedIn = auth.user !== null;

  useEffect(() => {
    chatBoxRef.current?.scrollTo({ top: chatBoxRef.current.scrollHeight, behavior: 'smooth' });
  }, [chatLog]);

  return (
    <>
      <Box
        ref={chatBoxRef}
        sx={{
          maxHeight: 200,
          overflowY: 'auto',
          mb: 2,
          paddingRight: 1,
          mt: 4,
        }}
      >
        {chatLog.map((msg, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: msg.sender === 'user' ? 'flex-end' : 'flex-start',
              mb: 1,
            }}
          >
            {msg.sender === 'goldBar' && (
              <Avatar src={goldBarImage} alt="Gold Bar" sx={{ width: 40, height: 40, mr: 1 }} />
            )}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: msg.sender === 'user' ? 'flex-end' : 'flex-start',
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  backgroundColor: msg.sender === 'user' ? 'primary.main' : 'grey.300',
                  color: msg.sender === 'user' ? 'white' : 'black',
                  borderRadius: 2,
                  padding: 1,
                  maxWidth: '75%',
                  wordBreak: 'break-word',
                  textAlign: 'left',
                }}
                dangerouslySetInnerHTML={{ __html: msg.text }}
              />

              {/* Render link chips if msg.links or msg.modalLinks are available */}
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 1 }}>
                {msg.links?.map((link, i) => (
                  <Chip
                    key={`link-${i}`}
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        {link.copy} <LaunchIcon sx={{ fontSize: 16 }} />
                      </Box>
                    }
                    component="a"
                    href={link.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    clickable
                    color="primary"
                    sx={{ cursor: 'pointer' }}
                  />
                ))}

                {/* New chip to trigger the FundAccount modal */}
                {msg.modalLinks?.map((modalLink, i) => (
                  <Chip
                    key={`modalLink-${i}`}
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        {modalLink.copy} <LaunchIcon sx={{ fontSize: 16 }} />
                      </Box>
                    }
                    onClick={() => setShowFundAccountModal(true)}
                    clickable
                    color="secondary"
                    sx={{ cursor: 'pointer' }}
                  />
                ))}
              </Box>
            </Box>

            {msg.sender === 'user' && (
              <Avatar
                src={gravatarUrl}
                alt={auth.user?.username}
                sx={{ width: 40, height: 40, ml: 1 }}
              />
            )}
          </Box>
        ))}
        {loading && <CircularProgress size={20} />}
      </Box>

      {/* Modal for FundingForm */}
      <Modal open={showFundAccountModal} onClose={() => setShowFundAccountModal(false)}>
        <Paper sx={{ padding: 3, margin: 'auto', maxWidth: 400, mt: 5 }}>
          <FundingForm /> {/* Render FundingForm inside the modal */}
          <Button onClick={() => setShowFundAccountModal(false)} fullWidth>
            Close
          </Button>
        </Paper>
      </Modal>

      {isLoggedIn && (
        <form onSubmit={handleCustomQuestionSubmit}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Ask a custom question"
            value={customQuestion}
            onChange={(e) => setCustomQuestion(e.target.value)}
            sx={{ mb: 1 }}
          />
          <Button type="submit" variant="contained" fullWidth>
            Submit
          </Button>
        </form>
      )}
    </>
  );
};

export default ChatView;
