import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Stack, Avatar } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import goldBarImage from '../GoldBar/clippy.png';

const ProductWidget = () => {
  const { slug } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const isLocal = process.env.REACT_APP_LOCAL === 'true';

    const fetchProduct = async () => {
      try {
        if (isLocal) {
          const staticResponse = await import('./fixtures/market.json');
          setProduct(staticResponse.product);
        } else {
          const response = await axios.get(`/api/products/${slug}`);
          setProduct(response.data.product);
        }
      } catch (error) {
        console.error('Failed to load product:', error);
      }
    };

    fetchProduct();
  }, [slug]);

  const imageThumb =
    product?.image_one_thumb || 'https://slideby.s3.us-west-1.amazonaws.com/placeholder-image.png';
  const productName = product?.title || 'Unknown Product';
  const brandName = 'Goldwater Bullion Exchange';
  const baseUrl = window.buildENV === 'staging' ? 'https://stage.goldwaterbullion.com' : 'https://www.goldwaterbullion.com';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '12px',
        backgroundColor: '#1a1a1a',
        color: '#fff',
        padding: '16px',
        width: '350px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
      }}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <img
          src={imageThumb}
          alt={productName}
          style={{ width: '60px', height: '60px', borderRadius: '8px' }}
        />
<Stack spacing={0.5} sx={{ flex: 1 }}>
  <Typography
    variant="h6"
    fontWeight="bold"
    component="a" // Turn Typography into a link
    href={`${baseUrl}/products/${slug}`}
    target="_blank"
    rel="noopener noreferrer"
    sx={{
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      whiteSpace: 'normal',
      textAlign: 'left',
      color: '#fff',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'underline',
      },
    }}
  >
    {productName}
  </Typography>
</Stack>
      </Stack>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ marginTop: '12px' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            sx={{ width: 45, height: 45, mr: 1 }}
            src={goldBarImage}
            alt="Gold Bar"
          />
          <Typography
            variant="caption"
            sx={{
              color: '#bbb', // Increase contrast for readability
              fontSize: '12px',
            }}
          >
            {brandName}
          </Typography>
        </Box>

        <Button
          size="small"
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          href={`${baseUrl}/products/${slug}`}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            minWidth: 'auto',
            padding: '4px 10px', // Smaller padding for a sleeker button
            fontSize: '12px', // Single-lined, smaller text
            backgroundColor: '#333',
            '&:hover': {
              backgroundColor: '#444',
            },
          }}
        >
          View Market
        </Button>
      </Stack>
    </Box>
  );
};

export default ProductWidget;
