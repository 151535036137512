import React from 'react';
import { Box, Typography, TextField, Button, Container } from '@mui/material';

const BankAccountConnection = ({ firstName, setFirstName, lastName, setLastName, handleLinkAccount }) => {
    return (
        <Container>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '50vh',
                    textAlign: 'center',
                    p: 4,
                }}
            >
                <Typography variant="h5" color="text.primary" gutterBottom>
                    Connect Bank Account
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ mb: 4, textAlign: 'left' }}>
                    We use Stripe as our payment processor. Stripe is a global leader in secure online payment processing,
                    handling billions of transactions for companies around the world. Your bank information is encrypted
                    and safely stored at stripe, ensuring your data is protected at all times.
                </Typography>

                <TextField
                    label="Legal First Name"
                    fullWidth
                    margin="normal"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                    label="Legal Last Name"
                    fullWidth
                    margin="normal"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />

                <Button
                    onClick={handleLinkAccount}
                    variant="contained"
                    color="primary"
                    sx={{ width: '100%' }}
                    disabled={!firstName || !lastName}
                >
                    Connect Bank Account
                </Button>
            </Box>
        </Container>
    );
};

export default BankAccountConnection;
