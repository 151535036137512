import React, { useState, useEffect, useContext } from "react";
import {
  Avatar,
  Box,
  Tabs,
  Tab,
  TextField,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Divider,
  ButtonGroup,
  Card,
  CircularProgress,
  Alert // Import Alert for warning messages
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ArrowForward, ArrowBack } from "@mui/icons-material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'; 
import { UserContext } from "../../contexts/UserContext";
import TollIcon from '@mui/icons-material/Toll';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Sell from './Sell';  

function BuySell({
  product,
  market = {},
  initialLimitPrice,
  setLimitPrice,
  onToggleProductId,
  onShowAssetSelector,
  lowestSellPrice
}) {
  const defaultPrice = market.sell || lowestSellPrice || initialLimitPrice || 0;
  const [selectedTab, setSelectedTab] = useState("buy");
  const [coinInput, setCoinInput] = useState(1);
  const [limitPrice, updateLimitPrice] = useState(defaultPrice);

  useEffect(() => {
    const newDefaultPrice = market.sell || lowestSellPrice || initialLimitPrice || 0;
    if (newDefaultPrice !== limitPrice) {
      updateLimitPrice(newDefaultPrice);
      setInputValue(newDefaultPrice.toFixed(2));
    }
  }, [market.sell, lowestSellPrice, initialLimitPrice]);

  const [shippingFee, setShippingFee] = useState(25)
  const [totalPrice, setTotalPrice] = useState(
    (
      (coinInput * defaultPrice + shippingFee).toFixed(2)
    )
  );
  const [subTotal, setSubTotal] = useState(
    (
      (coinInput * defaultPrice).toFixed(2)
    )
  );
  const [inputValue, setInputValue] = useState(defaultPrice.toFixed(2));
  const [activeButton, setActiveButton] = useState("market");
  const [showProductList, setShowProductList] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false); 
  const [errorMessage, setErrorMessage] = useState(""); // New state for error message
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  
  useEffect(() => {
    setTotalPrice(
      ((coinInput * limitPrice) + shippingFee).toFixed(2)
    );
    setSubTotal(
      (coinInput * limitPrice).toFixed(2)
    )
  }, [coinInput, limitPrice, shippingFee]);
  

  const navigateToWallet = () => {
    navigate('/wallet');
  };

  const fetchProducts = async () => {
    setLoading(true);
    const isLocal = process.env.REACT_APP_LOCAL === 'true';
    try {
      if (isLocal) {
        const mockData = await import('./fixtures/products.json');
        setProducts(mockData.default);
      } else {
        const response = await axios.get('/api/products');
        setProducts(response.data);
      }
    } catch (error) {
      console.error('Failed to fetch products', error);
    } finally {
      setLoading(false);
    }
  };

  const handleProductSelect = (selectedProduct) => {
    navigate(`/products/${selectedProduct.slug}`);
    setShowProductList(false);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleLimitButtonClick = (action) => {
    setActiveButton(action);
    let newPrice;
    switch (action) {
      case "market":
        newPrice = market.sell || lowestSellPrice || defaultPrice;
        break;
      case "down1":
        newPrice = limitPrice * 0.99;
        break;
      case "down5":
        newPrice = limitPrice * 0.95;
        break;
      case "down10":
        newPrice = limitPrice * 0.9;
        break;
      default:
        newPrice = limitPrice;
    }
    updateLimitPrice(newPrice);
    setInputValue(newPrice.toFixed(2));
    setTotalPrice(((coinInput * newPrice) + shippingFee).toFixed(2));
    setSubTotal((coinInput * newPrice).toFixed(2));
  };

  const onFinish = () => {
    const isLocal = process.env.REACT_APP_LOCAL === 'true';
    if (isLocal) {
      setShowReview(false);
      setShowConfirmation(true);
    } else {
      fetch('/orders/new', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          product_id: product.product_id,
          price: (parseFloat(limitPrice) * 100).toFixed(0),
          volume: coinInput,
          type: selectedTab.toUpperCase(),
        }),
      })
        .then((response) => {
          if (response.ok) {
            setShowReview(false);
            setShowConfirmation(true);  
            setErrorMessage(""); // Clear any previous error messages
          } else if (response.status === 400) {
            response.json().then((data) => {
              setErrorMessage(data.message); // Set the error message for 400 responses
            });
          } else {
            response.json().then((data) => alert(`Error: ${data.message}`));
          }
        })
        .catch((error) => {
          alert('Error placing order: ' + error.message);
        });
    }
  };

  const handleReviewOrder = () => {
    setShowReview(true);
  };

  const handleBack = () => {
    setErrorMessage("");
    setShowReview(false);
  };

  const handleDone = () => {
    setShowConfirmation(false);  
  };

  return (
    <Card variant="outlined">
      <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
        {showConfirmation ? (
          <Box sx={{ p: 3, textAlign: 'center' }}>
            <Avatar sx={{ bgcolor: 'primary.main', mx: 'auto', mb: 2 }}>
              <CheckCircleIcon />
            </Avatar>
            <Typography variant="h5" gutterBottom>Order submitted</Typography>
            <Typography variant="body1" sx={{ mb: 3 }}>
              We'll email you once this order changes status.
            </Typography>
            <Button 
              variant="contained" 
              color="primary" 
              fullWidth 
              sx={{ mb: 2 }} 
              onClick={handleDone}
            >
              Done
            </Button>
          </Box>
        ) : showReview ? (
          <Box sx={{ p: 3 }}>
            <IconButton onClick={handleBack}>
              <ArrowBack />
            </IconButton>
            {errorMessage && (
              <Alert severity="warning" sx={{ mb: 3 }}>
                {errorMessage}
              </Alert>
            )}
            <Box sx={{ textAlign: 'center', mb: 2 }}>
              <Avatar
                src={product.image_one_thumb || ''}
                alt={product.title}
                sx={{ width: 60, height: 60, mx: 'auto', mb: 1 }}
              />
              <Typography variant="h5">{`${selectedTab === 'buy' ? 'Buy' : 'Sell'} $${subTotal} of ${product.title}`}</Typography>
              <Typography variant="subtitle1">{`Price: $${inputValue}`}</Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="body2">{`${selectedTab === 'buy' ? 'Buy' : 'Sell'} Amount`}</Typography>
              <Typography variant="body2">{`${coinInput} ${product.title}`}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="body2">Add cash to</Typography>
              <Typography variant="body2">USD Wallet</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="body2">Shipping</Typography>
              <Typography variant="body2">${shippingFee}</Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography variant="body2">Total</Typography>
              <Typography variant="h6">{`$${totalPrice}`}</Typography>
            </Box>
            <Button 
              variant="contained" 
              color="success" 
              fullWidth 
              sx={{ mt: 2 }} 
              onClick={onFinish}
            >
              {selectedTab === 'buy' ? 'Place Bid' : 'Sell Now'}
            </Button>
          </Box>
        ) : showProductList ? (
          <Box sx={{ p: 3, maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden' }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <Box sx={{ mt: 2 }}>
                  {products.map((item) => (
                    <Button
                      key={item.slug}
                      variant="outlined"
                      fullWidth
                      sx={{ mb: 2, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                      onClick={() => handleProductSelect(item)}
                    >
                      <Avatar
                        src={item.image_one_thumb ? item.image_one_thumb : ''}
                        alt={item.title}
                        sx={{ width: 40, height: 40, mr: 2 }}
                      />
                      <Typography noWrap sx={{ textAlign: 'left' }}>{item.title}</Typography>
                    </Button>
                  ))}
                </Box>
              </>
            )}
          </Box>
        ) : (
          <>
            <Tabs value={selectedTab} onChange={handleTabChange} centered>
              <Tab label="Buy" value="buy" />
              <Tab label="Sell" value="sell" />
            </Tabs>
            <Divider />
            <Box sx={{ p: 3 }}>
              {selectedTab === "buy" ? (
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <TextField
                      label="Coins"
                      type="number"
                      value={coinInput}
                      onChange={(e) => setCoinInput(parseInt(e.target.value))}
                      InputProps={{ inputProps: { min: 1, step: 1 } }}
                      sx={{ mr: 2 }}
                    />
                    <TextField
                      label="Total Price"
                      type="text"
                      value={subTotal}
                      InputProps={{ readOnly: true }}
                    />
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="overline">LIMIT PRICE</Typography>
                    <TextField
                      type="text"
                      value={inputValue}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^-?[0-9]*(\.[0-9]*)?$/.test(value)) {
                          setInputValue(value);
                          const newLimitPrice = parseFloat(value);
                          if (!isNaN(newLimitPrice)) {
                            updateLimitPrice(newLimitPrice);
                          }
                        }
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">USD</InputAdornment>,
                      }}
                      fullWidth
                    />
                  </Box>
                  <ButtonGroup variant="contained" aria-label="Basic button group" fullWidth>
                    <Button
                      variant="contained"
                      color={activeButton === "market" ? "info" : "primary"}
                      onClick={() => handleLimitButtonClick("market")}
                    >
                      Market
                    </Button>
                    <Button
                      variant="contained"
                      color={activeButton === "down1" ? "info" : "primary"}
                      onClick={() => handleLimitButtonClick("down1")}
                      startIcon={<ArrowDownwardIcon />}
                    >
                      1%
                    </Button>
                    <Button
                      variant="contained"
                      color={activeButton === "down5" ? "info" : "primary"}
                      onClick={() => handleLimitButtonClick("down5")}
                      startIcon={<ArrowDownwardIcon />}
                    >
                      5%
                    </Button>
                    <Button
                      variant="contained"
                      color={activeButton === "down10" ? "info" : "primary"}
                      onClick={() => handleLimitButtonClick("down10")}
                      startIcon={<ArrowDownwardIcon />}
                    >
                      10%
                    </Button>
                  </ButtonGroup>
                  <Box sx={{ mb: 2 }}>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}
                      onClick={() => {
                        setShowProductList(true);
                        fetchProducts();
                      }}
                    >
                      <Typography sx={{ mt: 2 }} variant="overline">Buy:</Typography>
                      <Button
                        variant="outlined"
                        sx={{ mt: 2 }}
                        startIcon={<TollIcon />}
                      >
                        <Typography noWrap sx={{ maxWidth: '150px' }}>
                          {product.title}
                        </Typography>
                      </Button>
                      <IconButton sx={{ mt: 2 }}>
                        <ArrowForward />
                      </IconButton>
                    </Box>
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                    >
                      <Typography variant="overline">Pay With:</Typography>
                      <Button
                        variant="outlined"
                        startIcon={<AccountBalanceIcon />}
                        onClick={navigateToWallet}
                      >
                        Wallet
                      </Button>
                      <IconButton>
                        <ArrowForward />
                      </IconButton>
                    </Box>
                  </Box>
                  <Button 
                    variant="contained" 
                    color="success" 
                    fullWidth 
                    sx={{ mt: 2 }} 
                    onClick={handleReviewOrder}
                    endIcon={<ArrowForward />}
                  >
                    Review Bid
                  </Button>
                </Box>
              ) : (
                <Sell
                    product={product}
                    market={market}
                    initialLimitPrice={initialLimitPrice}
                    setLimitPrice={setLimitPrice}
                    onToggleProductId={onToggleProductId}
                    onShowAssetSelector={onShowAssetSelector}
                    highestBuyPrice={market.buy}
                  />
                )}
            </Box>
          </>
        )}
      </Box>
    </Card>
  );
}

export default BuySell;
