import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, Stack, Paper, Grid, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Breadcrumbs, Link, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAuth } from '../../contexts/AuthContext';

const AddressCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  height: '100%',
  boxShadow: theme.shadows[2],
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
}));

const DefaultBadge = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  backgroundColor: theme.palette.info.main,
  color: theme.palette.common.white,
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
}));

const AddAddressBox = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.grey[400]}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  textAlign: 'center',
  padding: theme.spacing(2),
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
}));

const AddressesPage = () => {
  const { auth } = useAuth();
  const [addresses, setAddresses] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false); 
  const [addressData, setAddressData] = useState({
    name: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    phone: '',
    email: '',
    residential: true,
    active: true, // Added active field to default state
  });
  const isLocal = process.env.REACT_APP_LOCAL === 'true';

  const fetchAddresses = async () => {
    try {
      if (isLocal) {
        const staticData = await import('./fixtures/addresses.json');
        setAddresses(staticData.default);
      } else {
        const response = await fetch(`/api/addresses`);
        if (!response.ok) {
          throw new Error('Error fetching addresses');
        }
        const data = await response.json();
        setAddresses(data);
      }
    } catch (error) {
      console.error('Error fetching addresses:', error);
    }
  };

  useEffect(() => {
    if (auth.user) {
      fetchAddresses();
    }
  }, [auth.user]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAddressData({ ...addressData, [name]: value });
  };

  const handleAddAddress = async () => {
    try {
      if (isLocal) {
        const newAddress = { ...addressData, id: `address-${addresses.length + 1}`, user_uuid: auth.user.id };
        setAddresses([...addresses, newAddress]);
      } else {
        const response = await fetch('/api/create-address', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_uuid: auth.user.id,
            ...addressData,
          }),
        });
        if (!response.ok) {
          throw new Error('Error adding address');
        }
        fetchAddresses(); 
      }
      setOpenDialog(false);
    } catch (error) {
      console.error('Error adding address:', error);
    }
  };

  const handleUpdateAddress = async (address_uuid) => {
    try {
      if (isLocal) {
        const updatedAddresses = addresses.map((address) =>
          address.id === address_uuid ? { ...address, ...addressData } : address
        );
        setAddresses(updatedAddresses);
      } else {
        const response = await fetch(`/api/update-address/${address_uuid}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_uuid: auth.user.id,
            ...addressData, // Ensure active field is included
          }),
        });
        if (!response.ok) {
          throw new Error('Error updating address');
        }
        fetchAddresses(); 
      }
      setOpenDialog(false);
      setIsEditing(false); 
    } catch (error) {
      console.error('Error updating address:', error);
    }
  };

  const handleRemoveAddress = async (address_uuid) => {
    try {
      if (isLocal) {
        const updatedAddresses = addresses.map((address) =>
          address.id === address_uuid ? { ...address, active: false } : address
        );
        setAddresses(updatedAddresses);
      } else {
        const response = await fetch(`/api/update-address/${address_uuid}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            active: false, 
          }),
        });
        if (!response.ok) {
          throw new Error('Error removing address');
        }
        fetchAddresses(); 
      }
    } catch (error) {
      console.error('Error removing address:', error);
    }
  };

  const handleAddAddressClick = () => {
    setOpenDialog(true);
    setIsEditing(false); 
    setAddressData({
      name: '',
      street1: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
      phone: '',
      email: '',
      residential: true,
      active: true, // Ensure active field is reset for new address
    });
  };

  const handleEditAddressClick = (address) => {
    setOpenDialog(true);
    setIsEditing(true); 
    setAddressData({ ...address, active: address.active !== undefined ? address.active : true }); // Preserve active field
  };

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2, marginTop: 2 }}>
        <Link underline="hover" color="inherit" href="/">
          Your Account
        </Link>
        <Typography color="textPrimary">Your Addresses</Typography>
      </Breadcrumbs>

      <Typography variant="h5" gutterBottom align="left">
        Your Addresses
      </Typography>

      <Box sx={{ margin: 3, marginBottom: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <AddAddressBox onClick={handleAddAddressClick}>
              <Typography variant="h4" color="textSecondary">+</Typography>
              <Typography variant="h6">Add Address</Typography>
            </AddAddressBox>
          </Grid>

          {addresses.map((address) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={address.id}>
              <AddressCard>
                {address.isDefault && <DefaultBadge>Default</DefaultBadge>}
                <Typography variant="h6">{address.name}</Typography>
                <Typography>{address.street1}</Typography>
                {address.street2 && <Typography>{address.street2}</Typography>}
                <Typography>{`${address.city}, ${address.state} ${address.zip}`}</Typography>
                <Typography>{address.country}</Typography>
                <Typography>{`Phone: ${address.phone}`}</Typography>
                <Typography>{`Email: ${address.email}`}</Typography>
                <Stack direction="row" spacing={1} sx={{ marginTop: 1 }}>
                  <Typography
                    component="a"
                    variant="body2"
                    color="primary"
                    onClick={() => handleEditAddressClick(address)} 
                    sx={{ cursor: 'pointer' }}
                  >
                    edit
                  </Typography>
                  <Typography variant="body2">|</Typography>
                  <Typography
                    component="a"
                    variant="body2"
                    color="primary"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handleRemoveAddress(address.uuid)} 
                  >
                    remove
                  </Typography>
                </Stack>
              </AddressCard>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{isEditing ? 'Update Address' : 'Add Address'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Name"
            name="name"
            value={addressData.name}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Street 1"
            name="street1"
            value={addressData.street1}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Street 2"
            name="street2"
            value={addressData.street2}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="City"
            name="city"
            value={addressData.city}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="State"
            name="state"
            value={addressData.state}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Zip"
            name="zip"
            value={addressData.zip}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Country"
            name="country"
            value={addressData.country}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Phone"
            name="phone"
            value={addressData.phone}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Email"
            name="email"
            value={addressData.email}
            onChange={handleInputChange}
            fullWidth
          />
        </DialogContent>
    <DialogActions>
      <Button onClick={() => setOpenDialog(false)} color="primary">
        Cancel
      </Button>
      <Button
        onClick={isEditing ? () => handleUpdateAddress(addressData.uuid) : handleAddAddress}
        color="primary"
      >
        {isEditing ? 'Update' : 'Add'}
      </Button>
    </DialogActions>
  </Dialog>
</Container>
);
};

export default AddressesPage;

