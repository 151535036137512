import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import SwitchAccessShortcutAddIcon from '@mui/icons-material/SwitchAccessShortcutAdd';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import md5 from 'md5';
import FundingForm from '../IndexPage/FundAccount';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import { 
  Box, Typography, Avatar, Button, Chip, Modal, Paper 
} from '@mui/material';

const pages = [
  { name: 'Gold', href: '/?b=1' },
  { name: 'Silver', href: '/?b=2' },
  { name: 'Sell with confidence', href: '/blog/sell-with-confidence' }
];

function ResponsiveAppBar({ onFundAccountClick }) {
  const { auth, logout } = useAuth();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();
  const emailHash = auth.user ? md5(auth.user.username.trim().toLowerCase()) : null;
  const gravatarUrl = emailHash ? `https://www.gravatar.com/avatar/${emailHash}?d=identicon` : null;
  const payment_method_ids = auth.user ? auth.user.payment_method_ids : [];
  const [showFundAccountModal, setShowFundAccountModal] = useState(false);
  const badgeValue =
  (auth.user?.tos_accepted !== true ? 1 : 0) +
  (auth.user?.verified !== 'verified' ? 1 : 0) +
  (!auth.user?.payouts_enabled ? 1 : 0) +
  (payment_method_ids.length <= 0 ? 1 : 0);


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleClickModal = () => {
    setAnchorElUser(null);
    setShowFundAccountModal(true)
  };


  const handleLogout = async () => {
    await logout();
    window.location.reload();
  };

  const handleBalance = () => {
    navigate('/wallet');
  };

  const handleAddress = () => {
    navigate('/a/addresses');
  };
  
  const handleFulfillment = () => {
    navigate('/fulfillment');
  };
  
  const handleOrders = () => {
    navigate('/orders');
  };
  
  const handleWithraw = () => {
    navigate('/withdraw/setup')
  };

  if (auth.loading) {
    return null; // or a loading indicator
  }

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <SwitchAccessShortcutAddIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Goldwater Bullion
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center" component="a" href={page.href}>
                    {page.name}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <SwitchAccessShortcutAddIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Goldwater Bullion
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                href={page.href}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, display: 'flex', alignItems: 'center' }}>
            {auth.user ? (
              <>
                <Chip
                  label="Transfer"
                  onClick={onFundAccountClick}
                  sx={{
                    backgroundColor: 'rgb(238, 195, 65)',
                    color: 'white',
                    borderRadius: '16px',
                    margin: 1,
                    '&:hover': {
                      backgroundColor: 'rgb(192, 192, 192)',  // Silver color
                    },
                  }}
                >
                  Transfer
                </Chip>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={auth.user?.username} src={gravatarUrl || undefined} />
                  </IconButton>
                </Tooltip>


                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {(auth.user.verified !== 'verified' || !auth.user.payouts_enabled || (payment_method_ids.length <= 0) ||  !auth.user.tos_accepted ) && (
                      <>
                        <Typography variant="body1" sx={{ px: 2, py: 1, color: 'text.secondary' }}>
                        <Badge
                            badgeContent={badgeValue}
                            color="primary"
                          >
                          Complete Onboarding
                          </Badge>
                        </Typography>

                        {payment_method_ids && payment_method_ids.length <= 0 && (
                          <MenuItem onClick={handleClickModal} >
                            <Typography textAlign="center">Add Payment Method</Typography>
                          </MenuItem>
                        )}

                        {auth.user.verified !== 'verified' && (
                          <MenuItem onClick={handleCloseUserMenu} component="a" href={`/partner/onboarding`}>
                            <Typography textAlign="center">Verify Identity</Typography>
                          </MenuItem>
                        )}

                        {!auth.user.payouts_enabled && (
                          <MenuItem onClick={handleCloseUserMenu} component="a" href={`/onboarding/banking`}>
                            <Typography textAlign="center">Enable Payouts</Typography>
                          </MenuItem>
                        )}

                        {!auth.user.tos_accepted && (
                          <MenuItem onClick={handleCloseUserMenu} component="a" href={`/onboarding/tos`}>
                            <Typography textAlign="center">Review Terms of Service</Typography>
                          </MenuItem>
                        )}
                        <Divider />
                      </>
                    )}

                    <MenuItem onClick={() => { handleCloseUserMenu(); handleBalance(); }}>
                      <Typography textAlign="center">Wallet</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => { handleCloseUserMenu(); handleAddress(); }}>
                      <Typography textAlign="center">Addresses</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => { handleCloseUserMenu(); handleFulfillment(); }}>
                      <Typography textAlign="center">Fulfillment</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => { handleCloseUserMenu(); handleOrders(); }}>
                      <Typography textAlign="center">Orders & Tracking</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => { handleCloseUserMenu(); handleLogout(); }}>
                      <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                  </Menu>

              </>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Chip
                  label="Sign In"
                  component="a"
                  href="/login"
                  clickable
                  sx={{ mr: 1, backgroundColor: 'primary.main', color: 'white', borderRadius: '16px' }}
                />
                <Chip
                  label="Sign Up"
                  component="a"
                  href="/welcome"
                  clickable
                  sx={{ backgroundColor: 'rgb(238, 195, 65)', color: 'white', borderRadius: '16px' }}
                />
              </Box>
            )}
          </Box>
        </Toolbar>
      </Container>
        {/* Modal for FundingForm */}
        <Modal open={showFundAccountModal} onClose={() => setShowFundAccountModal(false)}>
        <Paper sx={{ padding: 3, margin: 'auto', maxWidth: 400, mt: 5 }}>
          <FundingForm /> {/* Render FundingForm inside the modal */}
          <Button onClick={() => setShowFundAccountModal(false)} fullWidth>
            Close
          </Button>
        </Paper>
      </Modal>
    </AppBar>
  );
}

export default ResponsiveAppBar;
